/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	AddressInput:{

	},
	AssignedStaffOrFilter:{
		bdcId:"IDFilter",
		fniManagerId:"IDFilter",
		salesManagerId:"IDFilter",
		salesPersonId:"IDFilter"
	},
	CRM: "enum" as const,
	CdkConfigurationInput:{

	},
	CheckBoxGroupRules: "enum" as const,
	ComplianceStatus: "enum" as const,
	ComplianceTriple: "enum" as const,
	CreateCustomerInput:{

	},
	CreateEmploymentInput:{

	},
	CreateUserInput:{

	},
	CreateVehicleInput:{
		bodyType:"VehicleBodyType",
		condition:"VehicleCondition",
		lifeCycleStage:"VehicleLifeCycleStage",
		principalPriorUse:"VehiclePrincipalPriorUse"
	},
	CreditApplicationAddressInformationInput:{

	},
	CreditApplicationEmploymentInformationInput:{
		employmentStatus:"EmploymentStatus",
		incomeInterval:"IncomeInterval",
		otherIncomeInterval:"IncomeInterval"
	},
	CreditApplicationFormSubmissionDataInput:{

	},
	CreditApplicationFormSubmissionInput:{
		submissionData:"CreditApplicationFormSubmissionDataInput"
	},
	CreditApplicationInput:{
		currentAddress:"CreditApplicationAddressInformationInput",
		currentAddressHousingStatus:"HousingStatus",
		currentEmployment:"CreditApplicationEmploymentInformationInput",
		previousAddress:"CreditApplicationAddressInformationInput",
		previousEmployment:"CreditApplicationEmploymentInformationInput"
	},
	Customer:{
		documents:{
			filter:"DocumentFilter"
		},
		employments:{

		},
		formSubmissions:{
			filter:"FormSubmissionFilter"
		},
		hardCreditApplications:{
			filter:"HardCreditApplicationFilter",
			orderBy:"HardCreditApplicationOrderBy"
		},
		logs:{

		},
		nextDocumentsToSign:{

		},
		prequalApplications:{

		},
		residentialAddresses:{

		}
	},
	CustomerFilter:{
		transactionId:"IDFilter"
	},
	CustomerOrderBy:{
		createdAt:"FieldOrdering",
		firstName:"FieldOrdering",
		lastName:"FieldOrdering",
		status:"FieldOrdering"
	},
	CustomerSignupInput:{

	},
	CustomerTransactionRole: "enum" as const,
	Date: `scalar.Date` as const,
	DateTime: `scalar.DateTime` as const,
	DateTimeFilter:{
		equals:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		in:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		not:"DateTimeFilter"
	},
	Dealership:{
		adfXmlReceivers:{

		},
		customers:{
			orderby:"CustomerOrderBy"
		},
		homenetVehicles:{
			filter:"HomenetVehicleDataFilter"
		},
		transactions:{
			filter:"TransactionFilter",
			orderby:"TransactionOrderBy"
		},
		users:{
			filter:"UserDealershipFilter"
		}
	},
	DealershipFilter:{
		id:"IDFilter",
		slug:"IDFilter"
	},
	DealershipUpdateUser:{

	},
	DocumentFilter:{
		id:"IDFilter"
	},
	DocumentOrderBy:{
		createdAt:"OrderBy"
	},
	EmploymentStatus: "enum" as const,
	FieldOrdering: "enum" as const,
	FormCollectionStatus: "enum" as const,
	FormCollectionType: "enum" as const,
	FormFieldSubmissionFilter:{
		formSubmissionId:"IDFilter",
		id:"IDFilter",
		signerId:"IDFilter",
		value:"NotFilter"
	},
	FormFieldSubmissionListFilter:{
		every:"FormFieldSubmissionFilter",
		none:"FormFieldSubmissionFilter",
		some:"FormFieldSubmissionFilter"
	},
	FormFieldSubmissionOrderBy:{
		createdAt:"OrderBy"
	},
	FormFieldValue:{

	},
	FormRuleCreatorDealType: "enum" as const,
	FormRuleCreatorVehicleForPurchase: "enum" as const,
	FormSubmission:{
		formFieldSubmissions:{
			filter:"FormFieldSubmissionFilter"
		}
	},
	FormSubmissionAccessEmum: "enum" as const,
	FormSubmissionFilter:{
		formFieldSubmissions:"FormFieldSubmissionListFilter",
		id:"IDFilter",
		status:"FormSubmissionStatusFilter",
		type:"FormSubmissionTypeFilter"
	},
	FormSubmissionStatus: "enum" as const,
	FormSubmissionStatusFilter:{
		equals:"FormSubmissionStatus",
		not:"FormSubmissionStatusFilter",
		notIn:"FormSubmissionStatus"
	},
	FormSubmissionType: "enum" as const,
	FormSubmissionTypeFilter:{
		equals:"FormSubmissionType",
		in:"FormSubmissionType",
		not:"FormSubmissionTypeFilter"
	},
	HardCreditApplicationFilter:{
		id:"IDFilter"
	},
	HardCreditApplicationOrderBy:{
		createdAt:"OrderBy"
	},
	HomenetVehicleDataFilter:{
		id:"IDFilter"
	},
	HousingStatus: "enum" as const,
	IDFilter:{
		not:"IDFilter"
	},
	IdCardType: "enum" as const,
	IncomeInterval: "enum" as const,
	JSON: `scalar.JSON` as const,
	Mutation:{
		addScanToDealerJacket:{
			access:"FormSubmissionAccessEmum",
			addTo:"CustomerTransactionRole"
		},
		addScanToGloveCompartments:{

		},
		adminCheckVerificationPrerequisite:{

		},
		adminCleanUpScenarioRun:{

		},
		adminCreateAdfXmlReceiver:{
			adfXmlReceiver:"adminCreateAdfXmlReceiverInput"
		},
		adminCreateDealership:{
			dealership:"adminCreateDealershipInput"
		},
		adminCreateDealershipGroup:{
			dealershipGroup:"adminCreateDealershipGroupInput"
		},
		adminCreateForm:{
			form:"adminCreateFormInput"
		},
		adminCreateFormRules:{
			dealType:"FormRuleCreatorDealType",
			lifeCycle:"TransactionLifecycle",
			vehicleForPurchase:"FormRuleCreatorVehicleForPurchase"
		},
		adminCreateGroupAdmin:{

		},
		adminCreateStaff:{
			staff:"adminCreateAdminStaffInput"
		},
		adminDebugComplianceForms:{
			lifeCycle:"TransactionLifecycle"
		},
		adminDeleteFormRules:{

		},
		adminMarkTransactionDeleted:{

		},
		adminOnboardNewDealership:{
			dealership:"adminCreateDealershipInput",
			prequalConfig:"adminPrequalConfiguration"
		},
		adminRemoveGroupUser:{

		},
		adminSendTestEmail:{

		},
		adminSetFormOrder:{

		},
		adminStartScenarioRun:{

		},
		adminStartTestCase:{

		},
		adminStartTestGroupRun:{

		},
		adminSyncHomenetData:{

		},
		adminUpdate700CreditCredentials:{

		},
		adminUpdateAdfXmlReceiver:{
			adfXmlReceiver:"adminUpdateAdfXmlReceiverInput"
		},
		adminUpdateBuilderForm:{

		},
		adminUpdateCdkConfiguration:{
			cdkCrmConfiguration:"CdkConfigurationInput"
		},
		adminUpdateDealership:{
			dealership:"adminUpdateDealershipInput"
		},
		adminUpdateDealershipGroup:{
			dealershipGroup:"adminUpdateDealershipGroupInput"
		},
		adminUpdateForm:{
			form:"adminUpdateFormInput"
		},
		assignStaffToTransaction:{
			role:"Role"
		},
		cancelPreviousAndRequestSignatures:{
			lifeCycle:"TransactionLifecycle"
		},
		consentCheck:{

		},
		createAddress:{
			address:"AddressInput"
		},
		createAndSubmitCreditAndPrequalApplication:{
			formSubmissionData:"CreditApplicationFormSubmissionInput"
		},
		createCoBuyer:{
			coBuyer:"CreateCustomerInput"
		},
		createCreditApplicationAndCreateUser:{
			formData:"CreditApplicationInput"
		},
		createEmployment:{
			employment:"CreateEmploymentInput"
		},
		createGroupuser:{
			role:"Role"
		},
		createStaff:{
			user:"CreateUserInput"
		},
		createVehicle:{
			vehicle:"CreateVehicleInput",
			vehicleType:"VehicleType"
		},
		customerCreateTransaction:{
			source:"TransactionSource"
		},
		customerCreateUnauthenticatedTransaction:{
			source:"TransactionSource",
			user:"CreateUserInput"
		},
		customerSignupWithCognito:{
			user:"CustomerSignupInput"
		},
		dealershipCreateTransaction:{
			customer:"CreateCustomerInput",
			staff:"UpdateTransactionStaffInput"
		},
		dealershipUpdateUser:{
			updatedUser:"DealershipUpdateUser"
		},
		deleteAddress:{

		},
		deleteEmployment:{

		},
		login:{

		},
		makeUpload:{

		},
		makeUploadForScanner:{
			addTo:"CustomerTransactionRole"
		},
		makeUploadToTransaction:{
			addTo:"CustomerTransactionRole"
		},
		markTransactionAsDelivered:{

		},
		modifyHardCreditApplication:{
			formSubmissionData:"CreditApplicationFormSubmissionInput"
		},
		onboardingSubmitStep1:{
			step1:"SubmitStep1Input"
		},
		onboardingSubmitStep2:{
			step2:"SubmitStep2Input"
		},
		prefillLicensePlate:{

		},
		pushDataToCrms:{

		},
		pushDocumentToCdkDealJacket:{

		},
		pushDocumentsToCdk:{

		},
		pushFormSubmissionsToCdk:{

		},
		pushHardCreditApplication:{
			submitTo:"SubmitTo"
		},
		recordConsent:{

		},
		removeCoBuyer:{

		},
		removePrimaryBuyer:{

		},
		requestOtpExistingUserDEV:{

		},
		setStaffInformation:{
			staffInformation:"SetStaffInformationInput"
		},
		submitFormValues:{
			values:"FormFieldValue"
		},
		submitPrequalApplicationAndCreateUser:{
			formData:"PrequalInput"
		},
		submitPrequalApplicationOnTransaction:{
			formData:"PrequalInput"
		},
		submitStandardForm:{
			type:"FormSubmissionType"
		},
		suspendGroupUser:{

		},
		suspendStaff:{

		},
		switchCustomerRoles:{

		},
		updateAddress:{
			address:"AddressInput"
		},
		updateCustomer:{
			customer:"UpdateCustomerInput"
		},
		updateEmployment:{
			employment:"UpdateEmploymentInput"
		},
		updateGroupUser:{
			role:"Role"
		},
		updateMyUser:{
			user:"UpdateUserInput"
		},
		updateTransaction:{
			transaction:"UpdateTransactionInput"
		},
		updateUserDealershipRole:{
			role:"Role"
		},
		updateVehicle:{
			vehicle:"UpdateVehicleInput",
			vehicleType:"VehicleType"
		},
		uploadAdditionalDocuments:{

		},
		uploadAdditionalDocumentsToTransaction:{
			access:"FormSubmissionAccessEmum",
			addTo:"CustomerTransactionRole"
		},
		userExists:{

		},
		verifyOtpForExistingUser:{

		}
	},
	NotFilter:{
		not:"NotFilter"
	},
	OnboardingStep: "enum" as const,
	OrderBy: "enum" as const,
	PrequalInput:{
		birthdate:"DateTime"
	},
	Query:{
		customer:{

		},
		dealership:{

		},
		formBuilderState:{

		},
		formFields:{

		},
		node:{

		},
		nodes:{

		},
		testGroupRun:{

		},
		transaction:{

		},
		userDealership:{

		}
	},
	Role: "enum" as const,
	RoleFilter:{
		equals:"Role",
		in:"Role",
		not:"RoleFilter"
	},
	SetStaffInformationInput:{

	},
	StringFilter:{
		mode:"StringFilterMode",
		not:"StringFilter"
	},
	StringFilterMode: "enum" as const,
	SubmitStep1Input:{

	},
	SubmitStep2Input:{

	},
	SubmitTo: "enum" as const,
	TestRunStatus: "enum" as const,
	Transaction:{
		documents:{
			orderBy:"DocumentOrderBy"
		},
		formSubmissions:{
			filter:"FormSubmissionFilter",
			orderBy:"FormFieldSubmissionOrderBy"
		},
		logs:{
			orderBy:"TransactionLogOrderBy"
		}
	},
	TransactionFilter:{
		OR:"AssignedStaffOrFilter",
		createdAt:"DateTimeFilter",
		dealership:"DealershipFilter",
		id:"IDFilter",
		source:"TransactionSourceFilter",
		status:"TransactionStatusFilter"
	},
	TransactionLifecycle: "enum" as const,
	TransactionLogOrderBy:{
		createdAt:"OrderBy"
	},
	TransactionOrderBy:{
		createdAt:"FieldOrdering",
		firstName:"FieldOrdering",
		lastName:"FieldOrdering",
		source:"FieldOrdering",
		status:"FieldOrdering"
	},
	TransactionSource: "enum" as const,
	TransactionSourceFilter:{
		equals:"TransactionSource",
		in:"TransactionSource",
		not:"TransactionSourceFilter"
	},
	TransactionStatus: "enum" as const,
	TransactionStatusFilter:{
		equals:"TransactionStatus",
		in:"TransactionStatus",
		not:"TransactionStatusFilter"
	},
	UpdateCustomerInput:{
		dob:"DateTime",
		vehicleRegistrationAddressAnswer:"VehicleRegistrationAddressAnswer"
	},
	UpdateEmploymentInput:{

	},
	UpdateTransactionInput:{

	},
	UpdateTransactionStaffInput:{

	},
	UpdateUserInput:{

	},
	UpdateVehicleInput:{
		bodyType:"VehicleBodyType",
		condition:"VehicleCondition",
		lifeCycleStage:"VehicleLifeCycleStage",
		principalPriorUse:"VehiclePrincipalPriorUse"
	},
	User:{
		buyerFor:{
			filter:"TransactionFilter"
		},
		coBuyerFor:{
			filter:"TransactionFilter"
		},
		customers:{
			filter:"CustomerFilter"
		},
		staffInformation:{

		},
		userGroup:{
			filter:"UserGroupFilter"
		}
	},
	UserDealershipFilter:{
		role:"RoleFilter",
		user:"UserFilter",
		userId:"IDFilter"
	},
	UserFilter:{
		OR:"UserOrFilter",
		firstName:"StringFilter",
		lastName:"StringFilter"
	},
	UserGroupFilter:{

	},
	UserOrFilter:{
		firstName:"StringFilter",
		id:"IDFilter",
		lastName:"StringFilter"
	},
	Vehicle:{
		vehicleRecallChecks:{
			filter:"VehicleNhtsaRecallReportFilter",
			orderby:"VehicleNhtsaRecallReportOrderBy"
		},
		vinAuditReports:{
			filter:"VinAuditReportFilter",
			orderby:"VinAuditReportOrderBy"
		}
	},
	VehicleBodyType: "enum" as const,
	VehicleCondition: "enum" as const,
	VehicleLifeCycleStage: "enum" as const,
	VehicleNhtsaRecallReportFilter:{
		id:"IDFilter"
	},
	VehicleNhtsaRecallReportOrderBy:{
		createdAt:"OrderBy"
	},
	VehiclePrincipalPriorUse: "enum" as const,
	VehicleRegistrationAddressAnswer: "enum" as const,
	VehicleType: "enum" as const,
	VinAuditReportFilter:{
		id:"IDFilter"
	},
	VinAuditReportOrderBy:{
		createdAt:"OrderBy"
	},
	adminCreateAdfXmlReceiverInput:{
		targetCrm:"CRM"
	},
	adminCreateAdminStaffInput:{

	},
	adminCreateDealershipGroupInput:{

	},
	adminCreateDealershipInput:{

	},
	adminCreateFormInput:{

	},
	adminPrequalConfiguration:{

	},
	adminUpdateAdfXmlReceiverInput:{
		targetCrm:"CRM"
	},
	adminUpdateDealershipGroupInput:{

	},
	adminUpdateDealershipInput:{

	},
	adminUpdateFormInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	Address:{
		apartmentDetails:"String",
		city:"String",
		customer:"Customer",
		durationMonths:"Int",
		durationYears:"Int",
		fullAddress:"String",
		housingStatus:"String",
		id:"String",
		monthlyPayment:"Float",
		state:"String",
		street:"String",
		timelinePosition:"Int",
		transactionId:"String",
		userId:"String",
		zipCode:"String"
	},
	AdfXmlReceiver:{
		dealership:"Dealership",
		dealershipId:"String",
		email:"String",
		id:"String",
		receiveFromConsumerApp:"Boolean",
		receiveFromDashboard:"Boolean",
		receiveFromWebPrequal:"Boolean",
		receiveOnCreation:"Boolean",
		receiveOnUpdate:"Boolean",
		targetCrm:"CRM"
	},
	CheckBoxGroup:{
		id:"String",
		label:"String",
		n:"Int",
		rule:"CheckBoxGroupRules"
	},
	ComplianceData:{
		id:"ComplianceField",
		idQuizSummary:"ComplianceField",
		ofacStatus:"ComplianceField",
		recalls:"ComplianceField",
		redFlagStatus:"ComplianceField"
	},
	ComplianceField:{
		description:"String",
		status:"ComplianceStatus",
		title:"String"
	},
	ComplianceFormsData:{
		postPurchaseForms:"Form",
		prePurchaseForms:"Form",
		prePurchasePreCondition:"PrePurchasePreCondition"
	},
	ConsentCheckType:{
		hasConsentedToSms:"Boolean",
		userExists:"Boolean"
	},
	Customer:{
		birthdate:"String",
		compliance:"ComplianceData",
		contactAcknowledgement:"FormSubmission",
		createdAt:"DateTime",
		currentMonthlyPayment:"Float",
		disassociatedRole:"String",
		dob:"String",
		documents:"CustomerDocumentsConnection",
		employments:"CustomerEmploymentsConnection",
		firstName:"String",
		formSubmissions:"CustomerFormSubmissionsConnection",
		hardCreditApplications:"CustomerHardCreditApplicationsConnection",
		hasBeenRemovedFromTransaction:"Boolean",
		hasCoBuyer:"Boolean",
		hasInsurance:"Boolean",
		hasPrequalApplication:"Boolean",
		hasSubmittedHardCreditApplication:"Boolean",
		hasTradeVehicle:"Boolean",
		idCards:"IdCard",
		identityTheftPrevention:"FormSubmission",
		languageSelection:"String",
		lastName:"String",
		logs:"CustomerLogsConnection",
		middleName:"String",
		nextDocumentsToSign:"CustomerNextDocumentsToSignConnection",
		onboardingStep:"OnboardingStep",
		prequalApplications:"CustomerPrequalApplicationsConnection",
		privacyPolicy:"FormSubmission",
		residentialAddresses:"CustomerResidentialAddressesConnection",
		selfieCapture:"File",
		socialSecurityNumber:"String",
		testDriveDisclosure:"FormSubmission",
		transaction:"Transaction",
		transactionId:"String",
		user:"User",
		userId:"String",
		vehicleRegistrationAddress:"Address",
		vehicleRegistrationAddressAnswer:"VehicleRegistrationAddressAnswer",
		vehicleRegistrationAddressId:"String",
		wantsPayOff:"Boolean",
		wantsRegistrationAtSameAddress:"Boolean",
		wantsToPreQualify:"Boolean"
	},
	CustomerDocumentsConnection:{
		edges:"CustomerDocumentsConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	CustomerDocumentsConnectionEdge:{
		cursor:"String",
		node:"Document"
	},
	CustomerEmploymentsConnection:{
		edges:"CustomerEmploymentsConnectionEdge",
		pageInfo:"PageInfo"
	},
	CustomerEmploymentsConnectionEdge:{
		cursor:"String",
		node:"Employment"
	},
	CustomerFormSubmissionsConnection:{
		edges:"CustomerFormSubmissionsConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	CustomerFormSubmissionsConnectionEdge:{
		cursor:"String",
		node:"FormSubmission"
	},
	CustomerHardCreditApplicationsConnection:{
		edges:"CustomerHardCreditApplicationsConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	CustomerHardCreditApplicationsConnectionEdge:{
		cursor:"String",
		node:"HardCreditApplication"
	},
	CustomerLog:{
		createdAt:"DateTime",
		customer:"Customer",
		eventDescription:"String",
		executingUser:"User",
		id:"String"
	},
	CustomerLogsConnection:{
		edges:"CustomerLogsConnectionEdge",
		pageInfo:"PageInfo"
	},
	CustomerLogsConnectionEdge:{
		cursor:"String",
		node:"CustomerLog"
	},
	CustomerNextDocumentsToSignConnection:{
		edges:"CustomerNextDocumentsToSignConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	CustomerNextDocumentsToSignConnectionEdge:{
		cursor:"String",
		node:"FormSubmission"
	},
	CustomerPrequalApplicationsConnection:{
		edges:"CustomerPrequalApplicationsConnectionEdge",
		pageInfo:"PageInfo"
	},
	CustomerPrequalApplicationsConnectionEdge:{
		cursor:"String",
		node:"PrequalApplication"
	},
	CustomerResidentialAddressesConnection:{
		edges:"CustomerResidentialAddressesConnectionEdge",
		pageInfo:"PageInfo"
	},
	CustomerResidentialAddressesConnectionEdge:{
		cursor:"String",
		node:"Address"
	},
	CustomerSharedData:{
		buyerEmail:"String",
		buyerFirstName:"String",
		buyerLastName:"String",
		buyerName:"String",
		buyerOnboardingStep:"OnboardingStep",
		buyerPhoneNumber:"String",
		coBuyerEmail:"String",
		coBuyerFirstName:"String",
		coBuyerLastName:"String",
		coBuyerName:"String",
		coBuyerOnboardingStep:"OnboardingStep",
		coBuyerPhoneNumber:"String"
	},
	CustomerSignupResponse:{
		jwt:"String"
	},
	Date: `scalar.Date` as const,
	DateTime: `scalar.DateTime` as const,
	Dealership:{
		address:"String",
		adfXmlReceivers:"DealershipAdfXmlReceiversConnection",
		customers:"DealershipCustomersConnection",
		dealershipGroupId:"String",
		hasEnabledCdkCrm:"Boolean",
		hasEnabledCdkDms:"Boolean",
		hasEnabledComplianceForms:"Boolean",
		hasEnabledDealerTrack:"Boolean",
		hasEnabledDriversLicenseVerification:"Boolean",
		hasEnabledPostPurchaseDocs:"Boolean",
		hasEnabledPrequalFormService:"Boolean",
		hasEnabledRoute1:"Boolean",
		hasEnabledScanner:"Boolean",
		hasEnabledZipCodeOnSignUp:"Boolean",
		hasTdcCdkAppSubscriptionId:"Boolean",
		homenetVehicles:"DealershipHomenetVehiclesConnection",
		id:"String",
		logoUrl:"String",
		name:"String",
		phone:"String",
		salesPeople:"ListItem",
		slug:"String",
		supportedLanguages:"String",
		timeZone:"String",
		transactions:"DealershipTransactionsConnection",
		users:"DealershipUsersConnection",
		website:"String"
	},
	DealershipAdfXmlReceiversConnection:{
		edges:"DealershipAdfXmlReceiversConnectionEdge",
		pageInfo:"PageInfo"
	},
	DealershipAdfXmlReceiversConnectionEdge:{
		cursor:"String",
		node:"AdfXmlReceiver"
	},
	DealershipCustomersConnection:{
		edges:"DealershipCustomersConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	DealershipCustomersConnectionEdge:{
		cursor:"String",
		node:"Customer"
	},
	DealershipGroup:{
		address:"String",
		id:"String",
		name:"String",
		phoneNumber:"String"
	},
	DealershipHomenetVehiclesConnection:{
		edges:"DealershipHomenetVehiclesConnectionEdge",
		pageInfo:"PageInfo"
	},
	DealershipHomenetVehiclesConnectionEdge:{
		cursor:"String",
		node:"HomenetVehicleData"
	},
	DealershipPermType:{
		dealershipId:"String",
		dealershipName:"String",
		role:"Role"
	},
	DealershipTransactionsConnection:{
		edges:"DealershipTransactionsConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	DealershipTransactionsConnectionEdge:{
		cursor:"String",
		node:"Transaction"
	},
	DealershipUsersConnection:{
		edges:"DealershipUsersConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	DealershipUsersConnectionEdge:{
		cursor:"String",
		node:"UserDealership"
	},
	Document:{
		access:"String",
		category:"String",
		cdkDealJacketStatus:"String",
		createdAt:"DateTime",
		customer:"Customer",
		file:"File",
		id:"String",
		pdfIsEncrypted:"Boolean",
		title:"String",
		transactionId:"String",
		userId:"String"
	},
	Employment:{
		customer:"Customer",
		durationMonths:"Int",
		durationYears:"Int",
		employerName:"String",
		employerPhoneNumber:"String",
		employmentStatus:"String",
		id:"String",
		incomeAmount:"Float",
		incomeInterval:"String",
		occupation:"String",
		otherIncomeAmount:"Float",
		otherIncomeInterval:"String",
		otherIncomeSource:"String",
		timelinePosition:"Int",
		transactionId:"String",
		userId:"String"
	},
	File:{
		id:"String",
		url:"String"
	},
	Form:{
		createdAt:"DateTime",
		displayTitle:"String",
		formOrder:"FormOrder",
		id:"String",
		pdfFileUrl:"String"
	},
	FormField:{
		characterListLength:"Int",
		checkboxGroup:"CheckBoxGroup",
		createdAt:"DateTime",
		demoData:"String",
		enteredBy:"String",
		fixedValue:"String",
		form:"Form",
		formFieldId:"String",
		formFieldPlacements:"FormFieldPlacement",
		formId:"String",
		id:"String",
		prefillPath:"String",
		type:"String"
	},
	FormFieldPlacement:{
		characterListSpacing:"Float",
		createdAt:"DateTime",
		formField:"FormField",
		formFieldId:"String",
		formFieldPlacementId:"String",
		height:"Float",
		id:"String",
		page:"Int",
		positionX:"Float",
		positionY:"Float",
		textBold:"Boolean",
		textFontSize:"Float",
		textItalic:"Boolean",
		textUnderline:"Boolean",
		width:"Float"
	},
	FormFieldSubmission:{
		field:"FormField",
		fieldId:"ID",
		file:"File",
		formSubmission:"FormSubmission",
		value:"String"
	},
	FormOrder:{
		position:"Int"
	},
	FormRule:{
		id:"String"
	},
	FormSubmission:{
		access:"String",
		cdkDealJacketStatus:"String",
		createdAt:"DateTime",
		customer:"Customer",
		file:"File",
		fileId:"String",
		form:"Form",
		formCollection:"FormSubmissionCollection",
		formFieldSubmissions:"FormSubmissionFormFieldSubmissionsConnection",
		formSubmissionData:"String",
		id:"String",
		status:"String",
		type:"String",
		userId:"String"
	},
	FormSubmissionCollection:{
		id:"String",
		status:"FormCollectionStatus",
		type:"FormCollectionType"
	},
	FormSubmissionFormFieldSubmissionsConnection:{
		edges:"FormSubmissionFormFieldSubmissionsConnectionEdge",
		pageInfo:"PageInfo"
	},
	FormSubmissionFormFieldSubmissionsConnectionEdge:{
		cursor:"String",
		node:"FormFieldSubmission"
	},
	GraphQLError:{
		message:"String"
	},
	HardCreditApplication:{
		createdAt:"DateTime",
		dob:"Date",
		driversLicenseNumber:"String",
		driversLicenseState:"String",
		email:"String",
		employments:"JSON",
		firstName:"String",
		formSubmission:"FormSubmission",
		formSubmissionId:"String",
		homePhoneNumber:"String",
		id:"String",
		lastName:"String",
		middleName:"String",
		personalReferenceAddress:"Address",
		personalReferenceAddressId:"String",
		personalReferenceFirstName:"String",
		personalReferenceLastName:"String",
		personalReferenceMiddleName:"String",
		personalReferencePhoneNumber:"String",
		personalReferenceRelationship:"String",
		phoneNumber:"String",
		residentialAddresses:"JSON",
		socialSecurityNumber:"String",
		transaction:"Transaction",
		transactionId:"String",
		user:"User",
		userId:"String"
	},
	HomenetVehicleData:{
		bodyType:"String",
		color:"String",
		dealerId:"String",
		fuelType:"String",
		id:"String",
		locationId:"String",
		make:"String",
		mileage:"String",
		model:"String",
		rooftopId:"String",
		stockNumber:"String",
		transmission:"String",
		trim:"String",
		type:"String",
		vehicleId:"String",
		vin:"String",
		year:"String"
	},
	IdCard:{
		captureBack:"File",
		captureBackId:"String",
		captureFront:"File",
		captureFrontId:"String",
		cityInAddress:"String",
		class:"String",
		createdAt:"DateTime",
		dob:"DateTime",
		dobDateOnly:"Date",
		expirationDate:"DateTime",
		expirationDateOnly:"Date",
		eyeColor:"String",
		firstName:"String",
		height:"String",
		id:"String",
		idNumber:"String",
		issueDate:"DateTime",
		issueDateOnly:"Date",
		lastName:"String",
		middleName:"String",
		sex:"String",
		state:"String",
		stateInAddress:"String",
		streetInAddress:"String",
		suffix:"String",
		transactionId:"String",
		type:"String",
		userId:"String",
		zipCodeInAddress:"String"
	},
	JSON: `scalar.JSON` as const,
	LicensePrefillData:{
		make:"String",
		model:"String",
		trim:"String",
		vin:"String",
		year:"String"
	},
	ListItem:{
		label:"String",
		value:"String"
	},
	LoginResponse:{
		jwt:"String"
	},
	MakeFileUploadForScannerType:{
		fileId:"String",
		uploadUrl:"String"
	},
	MakeFileUploadType:{
		file:"File",
		fileId:"String",
		uploadUrl:"String"
	},
	MeType:{
		dealershipGroup:"DealershipGroup",
		dealershipPerms:"DealershipPermType",
		dealerships:"Dealership",
		ipAddress:"String",
		user:"User"
	},
	Mutation:{
		addScanToDealerJacket:"MutationAddScanToDealerJacketResult",
		addScanToGloveCompartments:"MutationAddScanToGloveCompartmentsResult",
		adminCheckVerificationPrerequisite:"MutationAdminCheckVerificationPrerequisiteResult",
		adminCleanUpScenarioRun:"MutationAdminCleanUpScenarioRunResult",
		adminCreateAdfXmlReceiver:"AdfXmlReceiver",
		adminCreateDealership:"Dealership",
		adminCreateDealershipGroup:"DealershipGroup",
		adminCreateForm:"Form",
		adminCreateFormRules:"MutationResponse",
		adminCreateGroupAdmin:"MutationResponse",
		adminCreateStaff:"User",
		adminDebugComplianceForms:"Form",
		adminDeleteFormRules:"MutationResponse",
		adminFinalizePartialFormSubmission:"MutationResponse",
		adminMarkTransactionDeleted:"MutationResponse",
		adminOnboardNewDealership:"Dealership",
		adminRemoveGroupUser:"MutationResponse",
		adminSendTestEmail:"Response",
		adminSetFormOrder:"Response",
		adminStartScenarioRun:"TestScenarioRun",
		adminStartTestCase:"MutationAdminStartTestCaseResult",
		adminStartTestGroupRun:"MutationAdminStartTestGroupRunResult",
		adminSyncHomenetData:"MutationResponse",
		adminUpdate700CreditCredentials:"Response",
		adminUpdateAdfXmlReceiver:"AdfXmlReceiver",
		adminUpdateBuilderForm:"MutationResponse",
		adminUpdateCdkConfiguration:"Response",
		adminUpdateDealership:"Dealership",
		adminUpdateDealershipGroup:"DealershipGroup",
		adminUpdateForm:"Form",
		assignStaffToTransaction:"MutationAssignStaffToTransactionResult",
		cancelPreviousAndRequestSignatures:"MutationCancelPreviousAndRequestSignaturesResult",
		consentCheck:"MutationConsentCheckResult",
		createAddress:"MutationCreateAddressResult",
		createAndSubmitCreditAndPrequalApplication:"MutationCreateAndSubmitCreditAndPrequalApplicationResult",
		createCoBuyer:"MutationCreateCoBuyerResult",
		createCreditApplicationAndCreateUser:"MutationCreateCreditApplicationAndCreateUserResult",
		createEmployment:"MutationCreateEmploymentResult",
		createGroupuser:"MutationCreateGroupuserResult",
		createStaff:"MutationCreateStaffResult",
		createVehicle:"MutationCreateVehicleResult",
		customerCreateTransaction:"MutationCustomerCreateTransactionResult",
		customerCreateUnauthenticatedTransaction:"MutationCustomerCreateUnauthenticatedTransactionResult",
		customerSignupWithCognito:"MutationCustomerSignupWithCognitoResult",
		dealershipCreateTransaction:"MutationDealershipCreateTransactionResult",
		dealershipUpdateUser:"MutationDealershipUpdateUserResult",
		deleteAddress:"MutationDeleteAddressResult",
		deleteEmployment:"MutationDeleteEmploymentResult",
		login:"MutationLoginResult",
		logout:"MutationLogoutResult",
		makeSubmissionToken:"MutationMakeSubmissionTokenResult",
		makeUpload:"MutationMakeUploadResult",
		makeUploadForScanner:"MutationMakeUploadForScannerResult",
		makeUploadToTransaction:"MutationMakeUploadToTransactionResult",
		markTransactionAsDelivered:"MutationMarkTransactionAsDeliveredResult",
		modifyHardCreditApplication:"MutationModifyHardCreditApplicationResult",
		onboardingSubmitStep1:"MutationOnboardingSubmitStep1Result",
		onboardingSubmitStep2:"MutationOnboardingSubmitStep2Result",
		prefillLicensePlate:"MutationPrefillLicensePlateResult",
		pushDataToCrms:"MutationPushDataToCrmsResult",
		pushDocumentToCdkDealJacket:"MutationPushDocumentToCdkDealJacketResult",
		pushDocumentsToCdk:"MutationPushDocumentsToCdkResult",
		pushFormSubmissionsToCdk:"MutationPushFormSubmissionsToCdkResult",
		pushHardCreditApplication:"MutationPushHardCreditApplicationResult",
		recordConsent:"MutationRecordConsentResult",
		removeCoBuyer:"MutationRemoveCoBuyerResult",
		removePrimaryBuyer:"MutationRemovePrimaryBuyerResult",
		requestOtpExistingUserDEV:"MutationRequestOtpExistingUserDEVResult",
		setStaffInformation:"MutationSetStaffInformationResult",
		submitFormValues:"MutationSubmitFormValuesResult",
		submitPrequalApplicationAndCreateUser:"MutationSubmitPrequalApplicationAndCreateUserResult",
		submitPrequalApplicationOnTransaction:"MutationSubmitPrequalApplicationOnTransactionResult",
		submitStandardForm:"MutationSubmitStandardFormResult",
		suspendGroupUser:"MutationSuspendGroupUserResult",
		suspendStaff:"MutationSuspendStaffResult",
		switchCustomerRoles:"MutationSwitchCustomerRolesResult",
		updateAddress:"MutationUpdateAddressResult",
		updateCustomer:"MutationUpdateCustomerResult",
		updateEmployment:"MutationUpdateEmploymentResult",
		updateGroupUser:"MutationUpdateGroupUserResult",
		updateMyUser:"MutationUpdateMyUserResult",
		updateTransaction:"MutationUpdateTransactionResult",
		updateUserDealershipRole:"MutationUpdateUserDealershipRoleResult",
		updateVehicle:"MutationUpdateVehicleResult",
		uploadAdditionalDocuments:"MutationUploadAdditionalDocumentsResult",
		uploadAdditionalDocumentsToTransaction:"MutationUploadAdditionalDocumentsToTransactionResult",
		userExists:"MutationUserExistsResult",
		verifyOtpForExistingUser:"MutationVerifyOtpForExistingUserResult"
	},
	MutationAddScanToDealerJacketResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationAddScanToDealerJacketSuccess":"MutationAddScanToDealerJacketSuccess"
	},
	MutationAddScanToDealerJacketSuccess:{
		data:"MutationResponse"
	},
	MutationAddScanToGloveCompartmentsResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationAddScanToGloveCompartmentsSuccess":"MutationAddScanToGloveCompartmentsSuccess"
	},
	MutationAddScanToGloveCompartmentsSuccess:{
		data:"MutationResponse"
	},
	MutationAdminCheckVerificationPrerequisiteResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationAdminCheckVerificationPrerequisiteSuccess":"MutationAdminCheckVerificationPrerequisiteSuccess"
	},
	MutationAdminCheckVerificationPrerequisiteSuccess:{
		data:"VerificationPrerequisiteResponseType"
	},
	MutationAdminCleanUpScenarioRunResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationAdminCleanUpScenarioRunSuccess":"MutationAdminCleanUpScenarioRunSuccess"
	},
	MutationAdminCleanUpScenarioRunSuccess:{
		data:"Response"
	},
	MutationAdminStartTestCaseResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationAdminStartTestCaseSuccess":"MutationAdminStartTestCaseSuccess"
	},
	MutationAdminStartTestCaseSuccess:{
		data:"TestCaseRun"
	},
	MutationAdminStartTestGroupRunResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationAdminStartTestGroupRunSuccess":"MutationAdminStartTestGroupRunSuccess"
	},
	MutationAdminStartTestGroupRunSuccess:{
		data:"TestGroupRun"
	},
	MutationAssignStaffToTransactionResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationAssignStaffToTransactionSuccess":"MutationAssignStaffToTransactionSuccess"
	},
	MutationAssignStaffToTransactionSuccess:{
		data:"Response"
	},
	MutationCancelPreviousAndRequestSignaturesResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCancelPreviousAndRequestSignaturesSuccess":"MutationCancelPreviousAndRequestSignaturesSuccess"
	},
	MutationCancelPreviousAndRequestSignaturesSuccess:{
		data:"MutationResponse"
	},
	MutationConsentCheckResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationConsentCheckSuccess":"MutationConsentCheckSuccess"
	},
	MutationConsentCheckSuccess:{
		data:"ConsentCheckType"
	},
	MutationCreateAddressResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCreateAddressSuccess":"MutationCreateAddressSuccess"
	},
	MutationCreateAddressSuccess:{
		data:"Address"
	},
	MutationCreateAndSubmitCreditAndPrequalApplicationResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCreateAndSubmitCreditAndPrequalApplicationSuccess":"MutationCreateAndSubmitCreditAndPrequalApplicationSuccess"
	},
	MutationCreateAndSubmitCreditAndPrequalApplicationSuccess:{
		data:"Response"
	},
	MutationCreateCoBuyerResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCreateCoBuyerSuccess":"MutationCreateCoBuyerSuccess"
	},
	MutationCreateCoBuyerSuccess:{
		data:"MutationResponse"
	},
	MutationCreateCreditApplicationAndCreateUserResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCreateCreditApplicationAndCreateUserSuccess":"MutationCreateCreditApplicationAndCreateUserSuccess"
	},
	MutationCreateCreditApplicationAndCreateUserSuccess:{
		data:"Response"
	},
	MutationCreateEmploymentResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCreateEmploymentSuccess":"MutationCreateEmploymentSuccess"
	},
	MutationCreateEmploymentSuccess:{
		data:"Employment"
	},
	MutationCreateGroupuserResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCreateGroupuserSuccess":"MutationCreateGroupuserSuccess"
	},
	MutationCreateGroupuserSuccess:{
		data:"MutationResponse"
	},
	MutationCreateStaffResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCreateStaffSuccess":"MutationCreateStaffSuccess"
	},
	MutationCreateStaffSuccess:{
		data:"User"
	},
	MutationCreateVehicleResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCreateVehicleSuccess":"MutationCreateVehicleSuccess"
	},
	MutationCreateVehicleSuccess:{
		data:"MutationResponse"
	},
	MutationCustomerCreateTransactionResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCustomerCreateTransactionSuccess":"MutationCustomerCreateTransactionSuccess"
	},
	MutationCustomerCreateTransactionSuccess:{
		data:"MutationResponse"
	},
	MutationCustomerCreateUnauthenticatedTransactionResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCustomerCreateUnauthenticatedTransactionSuccess":"MutationCustomerCreateUnauthenticatedTransactionSuccess"
	},
	MutationCustomerCreateUnauthenticatedTransactionSuccess:{
		data:"Response"
	},
	MutationCustomerSignupWithCognitoResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationCustomerSignupWithCognitoSuccess":"MutationCustomerSignupWithCognitoSuccess"
	},
	MutationCustomerSignupWithCognitoSuccess:{
		data:"Response"
	},
	MutationDealershipCreateTransactionResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationDealershipCreateTransactionSuccess":"MutationDealershipCreateTransactionSuccess"
	},
	MutationDealershipCreateTransactionSuccess:{
		data:"MutationResponseWithId"
	},
	MutationDealershipUpdateUserResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationDealershipUpdateUserSuccess":"MutationDealershipUpdateUserSuccess"
	},
	MutationDealershipUpdateUserSuccess:{
		data:"User"
	},
	MutationDeleteAddressResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationDeleteAddressSuccess":"MutationDeleteAddressSuccess"
	},
	MutationDeleteAddressSuccess:{
		data:"Address"
	},
	MutationDeleteEmploymentResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationDeleteEmploymentSuccess":"MutationDeleteEmploymentSuccess"
	},
	MutationDeleteEmploymentSuccess:{
		data:"Employment"
	},
	MutationLoginResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationLoginSuccess":"MutationLoginSuccess"
	},
	MutationLoginSuccess:{
		data:"LoginResponse"
	},
	MutationLogoutResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationLogoutSuccess":"MutationLogoutSuccess"
	},
	MutationLogoutSuccess:{
		data:"MutationResponse"
	},
	MutationMakeSubmissionTokenResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationMakeSubmissionTokenSuccess":"MutationMakeSubmissionTokenSuccess"
	},
	MutationMakeSubmissionTokenSuccess:{
		data:"String"
	},
	MutationMakeUploadForScannerResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationMakeUploadForScannerSuccess":"MutationMakeUploadForScannerSuccess"
	},
	MutationMakeUploadForScannerSuccess:{
		data:"MakeFileUploadForScannerType"
	},
	MutationMakeUploadResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationMakeUploadSuccess":"MutationMakeUploadSuccess"
	},
	MutationMakeUploadSuccess:{
		data:"MakeFileUploadType"
	},
	MutationMakeUploadToTransactionResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationMakeUploadToTransactionSuccess":"MutationMakeUploadToTransactionSuccess"
	},
	MutationMakeUploadToTransactionSuccess:{
		data:"MakeFileUploadType"
	},
	MutationMarkTransactionAsDeliveredResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationMarkTransactionAsDeliveredSuccess":"MutationMarkTransactionAsDeliveredSuccess"
	},
	MutationMarkTransactionAsDeliveredSuccess:{
		data:"MutationResponse"
	},
	MutationModifyHardCreditApplicationResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationModifyHardCreditApplicationSuccess":"MutationModifyHardCreditApplicationSuccess"
	},
	MutationModifyHardCreditApplicationSuccess:{
		data:"Response"
	},
	MutationOnboardingSubmitStep1Result:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationOnboardingSubmitStep1Success":"MutationOnboardingSubmitStep1Success"
	},
	MutationOnboardingSubmitStep1Success:{
		data:"MutationResponse"
	},
	MutationOnboardingSubmitStep2Result:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationOnboardingSubmitStep2Success":"MutationOnboardingSubmitStep2Success"
	},
	MutationOnboardingSubmitStep2Success:{
		data:"MutationResponse"
	},
	MutationPrefillLicensePlateResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationPrefillLicensePlateSuccess":"MutationPrefillLicensePlateSuccess"
	},
	MutationPrefillLicensePlateSuccess:{
		data:"LicensePrefillData"
	},
	MutationPushDataToCrmsResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationPushDataToCrmsSuccess":"MutationPushDataToCrmsSuccess"
	},
	MutationPushDataToCrmsSuccess:{
		data:"MutationResponse"
	},
	MutationPushDocumentToCdkDealJacketResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationPushDocumentToCdkDealJacketSuccess":"MutationPushDocumentToCdkDealJacketSuccess"
	},
	MutationPushDocumentToCdkDealJacketSuccess:{
		data:"Response"
	},
	MutationPushDocumentsToCdkResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationPushDocumentsToCdkSuccess":"MutationPushDocumentsToCdkSuccess"
	},
	MutationPushDocumentsToCdkSuccess:{
		data:"Response"
	},
	MutationPushFormSubmissionsToCdkResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationPushFormSubmissionsToCdkSuccess":"MutationPushFormSubmissionsToCdkSuccess"
	},
	MutationPushFormSubmissionsToCdkSuccess:{
		data:"Response"
	},
	MutationPushHardCreditApplicationResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationPushHardCreditApplicationSuccess":"MutationPushHardCreditApplicationSuccess"
	},
	MutationPushHardCreditApplicationSuccess:{
		data:"Response"
	},
	MutationRecordConsentResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationRecordConsentSuccess":"MutationRecordConsentSuccess"
	},
	MutationRecordConsentSuccess:{
		data:"Response"
	},
	MutationRemoveCoBuyerResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationRemoveCoBuyerSuccess":"MutationRemoveCoBuyerSuccess"
	},
	MutationRemoveCoBuyerSuccess:{
		data:"MutationResponse"
	},
	MutationRemovePrimaryBuyerResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationRemovePrimaryBuyerSuccess":"MutationRemovePrimaryBuyerSuccess"
	},
	MutationRemovePrimaryBuyerSuccess:{
		data:"MutationResponse"
	},
	MutationRequestOtpExistingUserDEVResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationRequestOtpExistingUserDEVSuccess":"MutationRequestOtpExistingUserDEVSuccess"
	},
	MutationRequestOtpExistingUserDEVSuccess:{
		data:"MutationResponse"
	},
	MutationResponse:{
		id:"String",
		status:"String"
	},
	MutationResponseWithId:{
		id:"String",
		status:"String"
	},
	MutationSetStaffInformationResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationSetStaffInformationSuccess":"MutationSetStaffInformationSuccess"
	},
	MutationSetStaffInformationSuccess:{
		data:"Response"
	},
	MutationSubmitFormValuesResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationSubmitFormValuesSuccess":"MutationSubmitFormValuesSuccess"
	},
	MutationSubmitFormValuesSuccess:{
		data:"MutationResponse"
	},
	MutationSubmitPrequalApplicationAndCreateUserResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationSubmitPrequalApplicationAndCreateUserSuccess":"MutationSubmitPrequalApplicationAndCreateUserSuccess"
	},
	MutationSubmitPrequalApplicationAndCreateUserSuccess:{
		data:"Response"
	},
	MutationSubmitPrequalApplicationOnTransactionResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationSubmitPrequalApplicationOnTransactionSuccess":"MutationSubmitPrequalApplicationOnTransactionSuccess"
	},
	MutationSubmitPrequalApplicationOnTransactionSuccess:{
		data:"Response"
	},
	MutationSubmitStandardFormResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationSubmitStandardFormSuccess":"MutationSubmitStandardFormSuccess"
	},
	MutationSubmitStandardFormSuccess:{
		data:"MutationResponse"
	},
	MutationSuspendGroupUserResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationSuspendGroupUserSuccess":"MutationSuspendGroupUserSuccess"
	},
	MutationSuspendGroupUserSuccess:{
		data:"MutationResponse"
	},
	MutationSuspendStaffResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationSuspendStaffSuccess":"MutationSuspendStaffSuccess"
	},
	MutationSuspendStaffSuccess:{
		data:"Response"
	},
	MutationSwitchCustomerRolesResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationSwitchCustomerRolesSuccess":"MutationSwitchCustomerRolesSuccess"
	},
	MutationSwitchCustomerRolesSuccess:{
		data:"MutationResponse"
	},
	MutationUpdateAddressResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUpdateAddressSuccess":"MutationUpdateAddressSuccess"
	},
	MutationUpdateAddressSuccess:{
		data:"Address"
	},
	MutationUpdateCustomerResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUpdateCustomerSuccess":"MutationUpdateCustomerSuccess"
	},
	MutationUpdateCustomerSuccess:{
		data:"MutationResponse"
	},
	MutationUpdateEmploymentResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUpdateEmploymentSuccess":"MutationUpdateEmploymentSuccess"
	},
	MutationUpdateEmploymentSuccess:{
		data:"Employment"
	},
	MutationUpdateGroupUserResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUpdateGroupUserSuccess":"MutationUpdateGroupUserSuccess"
	},
	MutationUpdateGroupUserSuccess:{
		data:"MutationResponse"
	},
	MutationUpdateMyUserResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUpdateMyUserSuccess":"MutationUpdateMyUserSuccess"
	},
	MutationUpdateMyUserSuccess:{
		data:"MutationResponse"
	},
	MutationUpdateTransactionResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUpdateTransactionSuccess":"MutationUpdateTransactionSuccess"
	},
	MutationUpdateTransactionSuccess:{
		data:"Response"
	},
	MutationUpdateUserDealershipRoleResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUpdateUserDealershipRoleSuccess":"MutationUpdateUserDealershipRoleSuccess"
	},
	MutationUpdateUserDealershipRoleSuccess:{
		data:"Response"
	},
	MutationUpdateVehicleResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUpdateVehicleSuccess":"MutationUpdateVehicleSuccess"
	},
	MutationUpdateVehicleSuccess:{
		data:"MutationResponse"
	},
	MutationUploadAdditionalDocumentsResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUploadAdditionalDocumentsSuccess":"MutationUploadAdditionalDocumentsSuccess"
	},
	MutationUploadAdditionalDocumentsSuccess:{
		data:"Document"
	},
	MutationUploadAdditionalDocumentsToTransactionResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUploadAdditionalDocumentsToTransactionSuccess":"MutationUploadAdditionalDocumentsToTransactionSuccess"
	},
	MutationUploadAdditionalDocumentsToTransactionSuccess:{
		data:"Response"
	},
	MutationUserExistsResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationUserExistsSuccess":"MutationUserExistsSuccess"
	},
	MutationUserExistsSuccess:{
		data:"Boolean"
	},
	MutationVerifyOtpForExistingUserResult:{
		"...on GraphQLError":"GraphQLError",
		"...on MutationVerifyOtpForExistingUserSuccess":"MutationVerifyOtpForExistingUserSuccess"
	},
	MutationVerifyOtpForExistingUserSuccess:{
		data:"LoginResponse"
	},
	Node:{
		"...on UserDealership": "UserDealership",
		id:"ID"
	},
	PageInfo:{
		endCursor:"String",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean",
		startCursor:"String"
	},
	PrePurchasePreCondition:{
		hasBuyerSubmittedHca:"ComplianceTriple",
		hasCoBuyerSubmittedHca:"ComplianceTriple",
		hasStaffAssigned:"Boolean",
		hasVehicleForPurchase:"Boolean"
	},
	PrequalApplication:{
		city:"String",
		id:"String",
		newestReportPrequalify:"PrequalReports",
		state:"String",
		transactionId:"String",
		userId:"String"
	},
	PrequalReports:{
		application:"PrequalApplication",
		id:"String",
		idMatchSummary:"String",
		ofacSummary:"String",
		product:"String",
		quizStatus:"String",
		redFlagSummary:"String",
		reportHtml:"String",
		response:"String",
		resultCode:"String",
		score:"String"
	},
	PublicType:{
		ipAddress:"String"
	},
	Query:{
		customer:"Customer",
		dealership:"Dealership",
		formBuilderState:"String",
		formFields:"FormField",
		forms:"Form",
		me:"MeType",
		node:"Node",
		nodes:"Node",
		public:"PublicType",
		qaDealership:"Dealership",
		testGroupRun:"TestGroupRun",
		transaction:"Transaction",
		userDealership:"UserDealership",
		verificationStructure:"TestGroupRun"
	},
	Response:{
		id:"String",
		status:"String"
	},
	StaffInformation:{
		cdkCrmEmployeeId:"String",
		dealership:"Dealership",
		dealershipId:"String",
		user:"User",
		userId:"String"
	},
	TestCaseRun:{
		createdAt:"DateTime",
		errorMessage:"String",
		id:"String",
		name:"String",
		resultPayload:"JSON",
		status:"TestRunStatus",
		testOrder:"Int",
		testScenario:"TestScenarioRun",
		testScenarioRunId:"String",
		updatedAt:"DateTime"
	},
	TestGeneratedResource:{
		createdAt:"DateTime",
		id:"String",
		name:"String",
		testScenarioRun:"TestScenarioRun",
		updatedAt:"DateTime",
		value:"String"
	},
	TestGroupRun:{
		createdAt:"DateTime",
		dealershipId:"String",
		id:"String",
		status:"TestRunStatus",
		testScenarioRuns:"TestScenarioRun",
		title:"String",
		updatedAt:"DateTime"
	},
	TestScenarioRun:{
		createdAt:"DateTime",
		generatedResources:"TestGeneratedResource",
		id:"String",
		initialPayload:"String",
		resourceCleanedUp:"Boolean",
		status:"TestRunStatus",
		testCaseRun:"TestCaseRun",
		testGroupRun:"TestGroupRun",
		testGroupRunId:"String",
		type:"String",
		updatedAt:"DateTime"
	},
	Transaction:{
		bdc:"User",
		buyer:"Customer",
		buyerId:"String",
		cdkDmsDealId:"String",
		coBuyer:"Customer",
		coBuyerId:"String",
		complianceFormsData:"ComplianceFormsData",
		consumerRebatesInUsdCents:"Int",
		createdAt:"DateTime",
		customerSharedData:"CustomerSharedData",
		dealership:"Dealership",
		dealershipCommitment:"String",
		documents:"TransactionDocumentsConnection",
		downPayment:"Int",
		downPaymentInUsdCents:"Int",
		financeType:"String",
		fniManager:"User",
		formSubmissions:"TransactionFormSubmissionsConnection",
		hardCreditApplDmsSubmittedAt:"DateTime",
		hardCreditApplDmsSubmittedTo:"String",
		id:"String",
		isLeaseBuyOut:"Boolean",
		leaseTermInMonths:"Int",
		logs:"TransactionLogsConnection",
		milesPerYearInLease:"Int",
		monthlyLeasePaymentInUsdCents:"Int",
		price:"Int",
		requestedPostPurchaseFormsAt:"DateTime",
		requestedPrePurchaseFormsAt:"DateTime",
		salesManager:"User",
		salesPerson:"User",
		salesPriceInUsdCents:"Int",
		salesTaxInBsp:"Int",
		source:"TransactionSource",
		status:"TransactionStatus",
		title:"String",
		tradeInPriceInUsdCents:"Int",
		tradeVehicle:"Vehicle",
		vehicle:"Vehicle"
	},
	TransactionDocumentsConnection:{
		edges:"TransactionDocumentsConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	TransactionDocumentsConnectionEdge:{
		cursor:"String",
		node:"Document"
	},
	TransactionFormSubmissionsConnection:{
		edges:"TransactionFormSubmissionsConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	TransactionFormSubmissionsConnectionEdge:{
		cursor:"String",
		node:"FormSubmission"
	},
	TransactionLog:{
		createdAt:"DateTime",
		eventDescription:"String",
		executingUser:"User",
		id:"String",
		transaction:"Transaction"
	},
	TransactionLogsConnection:{
		edges:"TransactionLogsConnectionEdge",
		pageInfo:"PageInfo"
	},
	TransactionLogsConnectionEdge:{
		cursor:"String",
		node:"TransactionLog"
	},
	User:{
		buyerFor:"UserBuyerForConnection",
		coBuyerFor:"UserCoBuyerForConnection",
		createdAt:"DateTime",
		customers:"UserCustomersConnection",
		email:"String",
		firstName:"String",
		id:"String",
		lastName:"String",
		phoneNumber:"String",
		staffInformation:"UserStaffInformationConnection",
		userGroup:"UserUserGroupConnection"
	},
	UserBuyerForConnection:{
		edges:"UserBuyerForConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	UserBuyerForConnectionEdge:{
		cursor:"String",
		node:"Transaction"
	},
	UserCoBuyerForConnection:{
		edges:"UserCoBuyerForConnectionEdge",
		pageInfo:"PageInfo",
		totalCount:"Int"
	},
	UserCoBuyerForConnectionEdge:{
		cursor:"String",
		node:"Transaction"
	},
	UserCustomersConnection:{
		edges:"UserCustomersConnectionEdge",
		pageInfo:"PageInfo"
	},
	UserCustomersConnectionEdge:{
		cursor:"String",
		node:"Customer"
	},
	UserDealership:{
		dealership:"Dealership",
		dealershipId:"String",
		id:"ID",
		isSuspended:"Boolean",
		role:"String",
		user:"User"
	},
	UserGroup:{
		group:"DealershipGroup",
		groupId:"String",
		id:"String",
		isSuspended:"Boolean",
		role:"String",
		user:"User"
	},
	UserStaffInformationConnection:{
		edges:"UserStaffInformationConnectionEdge",
		pageInfo:"PageInfo"
	},
	UserStaffInformationConnectionEdge:{
		cursor:"String",
		node:"StaffInformation"
	},
	UserUserGroupConnection:{
		edges:"UserUserGroupConnectionEdge",
		pageInfo:"PageInfo"
	},
	UserUserGroupConnectionEdge:{
		cursor:"String",
		node:"UserGroup"
	},
	Vehicle:{
		bodyType:"VehicleBodyType",
		color:"String",
		condition:"VehicleCondition",
		createdAt:"DateTime",
		id:"String",
		lifeCycleStage:"VehicleLifeCycleStage",
		make:"String",
		mileage:"Int",
		model:"String",
		pictureBack:"File",
		pictureFront:"File",
		pictureInterior:"File",
		pictureLeftSide:"File",
		pictureOdometer:"File",
		pictureRightSide:"File",
		pictureVinNumber:"File",
		principalPriorUse:"VehiclePrincipalPriorUse",
		registrationCard:"File",
		registrationName:"String",
		registrationState:"String",
		stockNumber:"String",
		transactionPurchaseVehicle:"Transaction",
		transactionTradeVehicle:"Transaction",
		trim:"String",
		updatedAt:"DateTime",
		updatedByDealership:"Boolean",
		vehicleRecallChecks:"VehicleVehicleRecallChecksConnection",
		vin:"String",
		vinAuditReports:"VehicleVinAuditReportsConnection",
		year:"String"
	},
	VehicleNhtsaRecallReport:{
		createdAt:"DateTime",
		id:"String",
		response:"JSON",
		updatedAt:"DateTime"
	},
	VehicleVehicleRecallChecksConnection:{
		edges:"VehicleVehicleRecallChecksConnectionEdge",
		pageInfo:"PageInfo"
	},
	VehicleVehicleRecallChecksConnectionEdge:{
		cursor:"String",
		node:"VehicleNhtsaRecallReport"
	},
	VehicleVinAuditReportsConnection:{
		edges:"VehicleVinAuditReportsConnectionEdge",
		pageInfo:"PageInfo"
	},
	VehicleVinAuditReportsConnectionEdge:{
		cursor:"String",
		node:"VinAuditReport"
	},
	VerificationPrerequisiteResponseType:{
		anyBDCExists:"Boolean",
		anyFNIExists:"Boolean",
		anySalesManagerExists:"Boolean",
		anySalesPersonExists:"Boolean",
		testAdminExists:"Boolean"
	},
	VinAuditReport:{
		createdAt:"DateTime",
		id:"String",
		response:"JSON",
		updatedAt:"DateTime"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}