import { TransactionSource } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';

const customerCreateTransactionAction = async ({
  dealershipSlug,
  language,
  firstName,
  lastName,
  salesPersonId
}: {
  dealershipSlug: string;
  language: string;
  firstName?: string;
  lastName?: string;
  salesPersonId?: string;
}) => {
  const resp = await gqlMutationClient({ dealershipSlug })({
    customerCreateTransaction: [
      {
        dealershipSlug,
        language,
        firstName,
        lastName,
        source: TransactionSource.KIOSK,
        salesPersonId
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationCustomerCreateTransactionSuccess': {
          data: {
            status: true,
            id: true
          }
        }
      }
    ]
  });

  if (
    !resp.customerCreateTransaction ||
    resp.customerCreateTransaction.__typename === 'GraphQLError'
  ) {
    throw new Error(
      resp.customerCreateTransaction?.message ?? 'Unexpected error'
    );
  }

  return resp.customerCreateTransaction.data;
};

export default customerCreateTransactionAction;
