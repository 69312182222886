import { TFunction } from 'i18next';
import { z } from 'zod';

export const DocumentTypeSchema = z.union([
  z.literal('BANK_STATEMENTS', { description: 'Bank Statements' }),
  z.literal('UTILITY_BILLS', { description: 'Utility Bills' }),
  z.literal('PROOF_OF_INCOME', { description: 'Proof of Income' }),
  z.literal('OTHER', { description: 'Other' })
]);

export type DocumentType = z.infer<typeof DocumentTypeSchema>;

export type DocumentTypeOption = {
  value: DocumentType;
  label: string;
  documentTitle: string;
};

/**
 * We maintain a separate name between to be translated and original documenTitle
 * because we need a default document title to be stored
 */
export const getDocumentTypeOptions = (t: TFunction): DocumentTypeOption[] => {
  return [
    {
      value: 'BANK_STATEMENTS',
      label: t('Bank Statements'),
      documentTitle: 'Bank Statements'
    },
    {
      value: 'UTILITY_BILLS',
      label: t('Utility Bills'),
      documentTitle: 'Utility Bills'
    },
    {
      value: 'PROOF_OF_INCOME',
      label: t('Proof of Income'),
      documentTitle: 'Proof of Income'
    },
    {
      value: 'OTHER',
      label: t('Other'),
      documentTitle: 'Other'
    }
  ];
};

// Used for display string that needs to be translated
export function getDocumentTypeLabel(
  value: DocumentType,
  t: TFunction
): string {
  return (
    getDocumentTypeOptions(t).find((option) => option.value === value)?.label ??
    value
  );
}

// Used for document title that needs to be stored
export function getDocumentTitle(value: DocumentType, t: TFunction): string {
  return (
    getDocumentTypeOptions(t).find((option) => option.value === value)
      ?.documentTitle ?? value
  );
}

export const stringToDocumentType = (s: string): DocumentType => {
  const DocumentTypeMap: { [key: string]: DocumentType } = {
    BANK_STATEMENTS: 'BANK_STATEMENTS',
    UTILITY_BILLS: 'UTILITY_BILLS',
    PROOF_OF_INCOME: 'PROOF_OF_INCOME',
    OTHER: 'OTHER'
  };

  return DocumentTypeMap[s];
};
