import { Add } from '@mui/icons-material';
import { Button } from '@thedealersconcierge/components';
import { useTranslation } from 'react-i18next';
import { BaseLayout } from '~/pages/scanner/_components/ScreenBaseLayout';
import { ScreenModes } from './ScreenController';

/**
 * This screen is responsible for Landing page after user open the Scanner Link
 */
export default function Landing({
  setScreenMode
}: {
  setScreenMode: (screenMode: ScreenModes) => void;
}) {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <BaseLayout.Top>
        <div className="w-full bg-secondary text-center">
          <p className="text-large font-bold pt-spacing-04 pb-spacing-02">
            {t('Document Scanner')}
          </p>
        </div>
      </BaseLayout.Top>

      <BaseLayout.Content>
        <div className="flex flex-col items-center justify-between h-full w-full py-32">
          <div className="flex flex-col *:text-center space-y-spacing-03 h-full items-center justify-center flex-grow px-spacing-08">
            <p className="text-large mb-spacing-02 font-bold">
              {t('Scan Documents')}
            </p>
            <p>{t('Take a photo of a document and save it as a PDF')}</p>
          </div>

          <Button
            className="mt-spacing-04"
            onClick={() => {
              setScreenMode('SCANNER');
            }}
            iconLeft={<Add />}
          />
        </div>
      </BaseLayout.Content>
    </BaseLayout>
  );
}
