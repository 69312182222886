import { SaveOutlined } from '@mui/icons-material';
import { Button } from '@thedealersconcierge/components';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import type { DocumentDetection } from '~/pages/scanner/_components/Scanner';
import { BaseLayout } from '~/pages/scanner/_components/ScreenBaseLayout';
import { ScreenModes } from './ScreenController';

export default function CropScreen({
  pages,
  activeIndex,
  setPages,
  setScreenMode
}: {
  pages: DocumentDetection[];
  activeIndex: number;
  setPages: (updated: DocumentDetection[]) => void;
  setScreenMode: (mode: ScreenModes) => void;
}) {
  const { t } = useTranslation();
  const [originalUrl, setOriginalUrl] = useState<string | null>(null);

  // Default crop area (100% of the image)
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100
  });

  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);

  const imageRef = useRef<HTMLImageElement | null>(null);

  // Converts a Crop (which can be in '%' or 'px') to a PixelCrop based on the displayed image size.
  // This should only happen on initialization since we set our default Crop zone to 100% of the Image
  const toPixelCrop = useCallback(
    (image: HTMLImageElement, crop: Crop): PixelCrop => {
      if (crop.unit === '%') {
        const rect = image.getBoundingClientRect();
        const displayedWidth = rect.width;
        const displayedHeight = rect.height;

        return {
          unit: 'px',
          x: (crop.x * displayedWidth) / 100,
          y: (crop.y * displayedHeight) / 100,
          width: (crop.width * displayedWidth) / 100,
          height: (crop.height * displayedHeight) / 100
        };
      } else {
        // If crop.unit is 'px', return as is
        return crop as PixelCrop;
      }
    },
    []
  );

  /**
   * Draws the cropped area onto a canvas and returns a Blob.
   */
  async function getCroppedBlobDirect(
    image: HTMLImageElement,
    crop: Crop
  ): Promise<Blob> {
    const pixelCrop = toPixelCrop(image, crop);

    // Calculate scale between natural size and displayed size
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Map displayed pixel crop to natural image pixels
    const finalCrop: PixelCrop = {
      x: pixelCrop.x * scaleX,
      y: pixelCrop.y * scaleY,
      width: pixelCrop.width * scaleX,
      height: pixelCrop.height * scaleY,
      unit: 'px'
    };

    // Create canvas with the size of the crop
    const canvas = document.createElement('canvas');
    canvas.width = Math.floor(finalCrop.width);
    canvas.height = Math.floor(finalCrop.height);
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error(t('Canvas 2D context not available'));

    // Draw the cropped image onto the canvas
    ctx.drawImage(
      image,
      finalCrop.x,
      finalCrop.y,
      finalCrop.width,
      finalCrop.height,
      0,
      0,
      finalCrop.width,
      finalCrop.height
    );

    // Convert the canvas to a Blob
    return new Promise<Blob>((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) resolve(blob);
          else
            reject(
              new Error(t('Canvas is empty or failed to convert to Blob'))
            );
        },
        'image/png',
        1
      );
    });
  }

  // Load the image URL based on the active page
  useEffect(() => {
    const page = pages[activeIndex];
    if (!page) return;

    const buffer = page.cropped ?? page.original;
    if (buffer) {
      const blob = new Blob([buffer], { type: 'image/png' });
      const url = URL.createObjectURL(blob);
      setOriginalUrl(url);
    }

    return () => {
      if (originalUrl) {
        URL.revokeObjectURL(originalUrl);
      }
    };
  }, [pages, activeIndex]);

  const handleSave = async () => {
    if (!imageRef.current || !completedCrop) return;

    try {
      const croppedBlob = await getCroppedBlobDirect(imageRef.current, crop);
      const arrayBuffer = await croppedBlob.arrayBuffer();
      const croppedArray = new Uint8Array(arrayBuffer);

      // Update the cropped image in pages
      const updatedPages = [...pages];
      updatedPages[activeIndex] = {
        ...updatedPages[activeIndex],
        cropped: croppedArray
      };

      setPages(updatedPages);
      setScreenMode('REVIEW_SINGULAR');
    } catch (err) {
      // This needs to be handled better since the views are for mobile
      toast.error(t('Failed saving cropped image'));
      console.error(err);
    }
  };

  const onImageLoad = (image: HTMLImageElement) => {
    imageRef.current = image;
  };

  return (
    <BaseLayout>
      {/* Top Bar */}
      <BaseLayout.Top>
        <div className="flex flex-row justify-between items-center w-full px-spacing-04 py-spacing-02 border-b">
          <Button
            variant="GHOST"
            label="Cancel"
            size="SMALL"
            onClick={() => {
              setScreenMode('REVIEW_SINGULAR');
            }}
          />
          <div className="text-center font-medium"></div>
          <Button
            variant="GHOST"
            label={t('Save')}
            size="SMALL"
            onClick={() => {
              void handleSave();
            }}
            disabled={!imageRef.current || !completedCrop}
          />
        </div>
      </BaseLayout.Top>

      {/* Main Content: ReactCrop + Preview Canvas */}
      <BaseLayout.Content>
        <div className="flex flex-col items-center justify-center w-full h-full p-4 bg-gray-100 overflow-y-scroll">
          {originalUrl ? (
            <>
              <div className="mb-4">
                <ReactCrop
                  crop={crop}
                  onChange={(newCrop) => {
                    setCrop(newCrop);
                  }}
                  onComplete={(c) => {
                    setCompletedCrop(c);
                  }}
                  aspect={undefined} // Set a fixed aspect ratio if needed
                  keepSelection
                >
                  <img
                    src={originalUrl}
                    alt="To Crop"
                    onLoad={(e) => {
                      onImageLoad(e.currentTarget);
                    }}
                    style={{
                      maxHeight: '80vh',
                      objectFit: 'contain',
                      display: 'block',
                      margin: '0 auto'
                    }}
                  />
                </ReactCrop>
              </div>
            </>
          ) : (
            <p>{t('No image available for cropping')}</p>
          )}
        </div>
      </BaseLayout.Content>

      {/* Footer with Save Button */}
      <BaseLayout.Footer>
        <div className="p-2 border-t flex justify-around z-20">
          <div
            className={classNames(
              'flex flex-col items-center text-primary-brand cursor-pointer px-spacing-03 py-spacing-02 gap-spacing-01',
              {
                'text-gray-200': !imageRef.current || !completedCrop
              }
            )}
            onClick={() => {
              if (!imageRef.current || !completedCrop) return;
              void handleSave();
            }}
          >
            <SaveOutlined />
            <p className="label-02 font-normal">{t('Save')}</p>
          </div>
        </div>
      </BaseLayout.Footer>
    </BaseLayout>
  );
}
