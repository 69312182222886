import { TFunction } from 'i18next';
import { CustomerTransactionRole } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';

const uploadScannerFileAction = async ({
  submissionToken,
  addTo,
  transactionId,
  pdfBlob,
  t
}: {
  submissionToken?: string;
  addTo?: CustomerTransactionRole;
  transactionId: string;
  pdfBlob: Blob;
  t: TFunction;
}) => {
  const makeUploadRes = await gqlMutationClient({ dealershiplessAuth: true })({
    makeUploadForScanner: [
      { submissionToken, addTo, transactionId },
      {
        __typename: true,
        '...on GraphQLError': { message: true },
        '...on MutationMakeUploadForScannerSuccess': {
          data: {
            fileId: true,
            uploadUrl: true
          }
        }
      }
    ]
  });

  if (
    !makeUploadRes.makeUploadForScanner ||
    makeUploadRes.makeUploadForScanner.__typename === 'GraphQLError'
  ) {
    throw new Error(
      makeUploadRes.makeUploadForScanner?.message ??
        t('Unexpected error when uploading scanner files')
    );
  }

  // Make sure fileId and uploadUrl Exists
  const { fileId, uploadUrl } = makeUploadRes.makeUploadForScanner.data;
  if (!fileId || !uploadUrl) {
    throw new Error(t('An Error happened when provisioning the file'));
  }

  await fetch(uploadUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': `application/pdf`,
      'Content-Length': pdfBlob.size.toString()
    },
    body: pdfBlob
  });

  return { fileId };
};

export default uploadScannerFileAction;
