import { DeleteOutline } from '@mui/icons-material';
import { Button } from '@thedealersconcierge/components';
import classNames from 'classnames';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DocumentDetection } from '~/pages/scanner/_components/Scanner';
import { BaseLayout } from '~/pages/scanner/_components/ScreenBaseLayout';
import { ScreenModes } from './ScreenController';

/**
 * This page is currently do not have the reordering functionality and only function as an Overview Page
 */
export default function ReorderScreen({
  pages,
  setScreenMode,
  onDeleteAll,
  onDeleteByIndex
}: {
  pages: DocumentDetection[];
  setScreenMode: (screenMode: ScreenModes) => void;
  onDeleteAll: () => void;
  onDeleteByIndex?: (index: number) => void;
}) {
  const { t } = useTranslation();
  const handleDeleteAll = useCallback(() => {
    if (onDeleteAll) {
      const yes = window.confirm(
        t('Are you sure you want to delete all scans?')
      );
      if (yes) onDeleteAll();
    }
  }, [onDeleteAll]);

  const handleDeleteIndex = useCallback(
    (index: number) => {
      if (onDeleteByIndex) {
        const yes = window.confirm(
          t('Are you sure you want to delete this item?')
        );
        if (yes) onDeleteByIndex(index);
      }
    },
    [onDeleteByIndex]
  );

  // Reverse the array so latest item pop earlier
  const reversedPages = pages.reverse();

  return (
    <div className="flex flex-col h-screen w-screen max-w-lg bg-white">
      {/* Header */}
      <BaseLayout.Top>
        <div className="flex flex-row justify-between w-full items-center px-spacing-04 py-spacing-02 border-b">
          <Button
            variant="GHOST"
            label={t('Done')}
            size="SMALL"
            onClick={() => {
              setScreenMode('SCANNER');
            }}
          />

          <Trans t={t} values={{ pageLength: pages.length ?? '' }}>
            <span className="font-normal text-body-3 px-3">
              Total of {'{{pageLength}}'} Items
            </span>
          </Trans>
        </div>
      </BaseLayout.Top>

      {/* Content - Grid of thumbnails */}
      <BaseLayout.Content>
        <div className="flex-1 overflow-auto p-4">
          <div className="grid grid-cols-3 gap-4">
            {reversedPages.map((p, idx) => {
              const blob = p.cropped
                ? new Blob([p.cropped], { type: 'image/png' })
                : null;
              const url = blob ? URL.createObjectURL(blob) : undefined;

              return (
                <div
                  key={idx}
                  className="group relative p-2 flex flex-col items-center w-full"
                >
                  {/* Delete button */}
                  <div className="absolute top-1 right-1 -mt-3 -mr-3 z-10 ">
                    <button
                      onClick={() => {
                        handleDeleteIndex(idx);
                      }}
                      className="p-1 bg-white rounded-full shadow-sm border border-blue-500 w-8 h-8 flex flex-row items-center justify-center"
                    >
                      <DeleteOutline
                        className="text-primary-brand"
                        sx={{
                          fontSize: 16
                        }}
                      />
                    </button>
                  </div>

                  {/* A4 Paper aspect ratio container */}
                  <div className="w-full aspect-[1/1.414] flex-1">
                    {url ? (
                      <img
                        src={url}
                        alt={`Page ${reversedPages.length - idx}`}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-300 flex items-center justify-center">
                        <span className="text-gray-500">
                          {t('Document placeholder')}
                        </span>
                      </div>
                    )}
                  </div>

                  <span className="mt-1 text-body-3 font-normal px-2 rounded">
                    {idx + 1}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </BaseLayout.Content>

      {/* Bottom bar - Delete All button */}
      <BaseLayout.Footer>
        <div className="p-2 border-t flex justify-center">
          <div
            className={classNames(
              'flex flex-col items-center text-primary-brand group cursor-pointer px-spacing-03 py-spacing-02 gap-spacing-01',
              {
                'text-gray-300 cursor-not-allowed"': pages.length === 0
              }
            )}
            onClick={() => {
              if (pages.length > 0) handleDeleteAll();
            }}
          >
            <DeleteOutline />
            <p
              className={classNames(
                'text-primary-brand whitespace-nowrap label-02 font-normal px-[2px]',
                {
                  'text-gray-300 cursor-not-allowed"': pages.length === 0
                }
              )}
            >
              {t('Delete All')}
            </p>
          </div>
        </div>
      </BaseLayout.Footer>
    </div>
  );
}
