import { UseQueryOptions } from '@tanstack/react-query';
import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { DealershipIdentifier } from '~/globals';
import { gqlQueryClient } from '~/lib/backend';

const queryKey = (dealership?: DealershipIdentifier) => [
  'dealership',
  dealership
    ? 'dealershipId' in dealership
      ? dealership.dealershipId
      : 'dealershipSlug' in dealership
        ? dealership.dealershipSlug
        : undefined
    : undefined
];

// Define select here to derive typing
const selector = (dealership?: DealershipIdentifier) =>
  Selector('Query')({
    dealership: [
      {
        id:
          dealership && 'dealershipId' in dealership
            ? dealership.dealershipId
            : undefined,
        slug:
          dealership && 'dealershipSlug' in dealership
            ? dealership.dealershipSlug
            : undefined
      },
      {
        hasEnabledPrequalFormService: true,
        id: true,
        name: true,
        website: true,
        logoUrl: true,
        slug: true,
        supportedLanguages: true,
        salesPeople: {
          label: true,
          value: true
        },
        hasEnabledZipCodeOnSignUp: true
      }
    ]
  });
export type DealershipQueryType = InputType<
  GraphQLTypes['Query'],
  ReturnType<typeof selector>
>;

/**
 * This query can be used in all react components. The reason why we store it
 * on the outermost level her (and not just the selector) is to ensure that we
 * reuse the query selector.
 *
 * @returns
 */
export default function dealershipQuery(
  dealership?: DealershipIdentifier
): UseQueryOptions<DealershipQueryType> {
  return {
    // Disable the query when transactionId or userId is not provided
    enabled: Boolean(dealership),
    queryKey: queryKey(dealership),
    queryFn: () => {
      return gqlQueryClient({
        ...(dealership ?? { dealershiplessAuth: true })
      })(selector(dealership));
    }
  };
}
