import { useParams } from '~/router';
import ScrenController from '../../_screens/ScreenController';

export default function KioskScannerPage() {
  const { transactionId, temporaryToken } = useParams(
    '/scanner/:transactionId/:temporaryToken/kiosk'
  );
  return (
    <ScrenController
      flowType="KIOSK"
      transactionId={transactionId}
      temporaryToken={temporaryToken}
    />
  );
}
