import { Children, FC, isValidElement, ReactElement, ReactNode } from 'react';

type BaseLayoutProps = {
  children: ReactNode;
};

type SlotProps = {
  children: ReactNode;
};

type BaseLayoutComponent = FC<BaseLayoutProps> & {
  Top: FC<SlotProps>;
  Content: FC<SlotProps>;
  Footer: FC<SlotProps>;
};

const isLayoutElementType = <P,>(
  child: ReactNode,
  candidate: FC<P>
): child is ReactElement<P> =>
  isValidElement<P>(child) && child.type === candidate;

/**
 * This scans for <BaseLayout.Top>, <BaseLayout.Content>,
 * and <BaseLayout.Footer> components and assign it to the appropriate 'slots'
 *
 * About this pattern:
 * This pattern is called Compound Component Pattern this pattern exists in a lot of Modern library such as Material-UI and Chakra-UI
 * Mostly the usage of this pattern is to provide better readability
 *
 * So instead of:
 *
 * <BaseLayout
 *   top={}
 *   content={} />
 *
 * We have:
 *
 * <BaseLayout>
 *    <BaseLayout.Top> </BaseLayout.Top>
 *    <BaseLayout.Content> </BaseLayout.Content>
 * </BaseLayout>
 */
const BaseLayoutResolver = ({ children }: BaseLayoutProps) => {
  // Subcomponent are assigned into these slots
  const slots: {
    top?: ReactNode;
    content?: ReactNode;
    footer?: ReactNode;
  } = {
    top: undefined,
    content: undefined,
    footer: undefined
  };

  // Examine all children to see which slot each belongs to.
  Children.forEach(children, (child) => {
    if (isLayoutElementType(child, BaseLayout.Top)) {
      slots.top = child.props.children;
    } else if (isLayoutElementType(child, BaseLayout.Content)) {
      slots.content = child.props.children;
    } else if (isLayoutElementType(child, BaseLayout.Footer)) {
      slots.footer = child.props.children;
    }
  });

  const { top, content, footer } = slots;

  return (
    <div className="flex flex-col h-dvh">
      {top && <div className="flex-shrink-0">{top}</div>}

      {/* min-h-0 make the content stretch */}
      <div className="flex flex-col flex-grow min-h-0 relative">{content}</div>

      {footer && <div className="flex-shrink-0">{footer}</div>}
    </div>
  );
};

/**
 * Sub‐components that only render their children. They do nothing by themselves;
 * they're just “markers” for the parent layout to identify in the tree.
 */
const BaseLayoutTop = ({ children }: SlotProps) => <>{children}</>;

const BaseLayoutContent = ({ children }: SlotProps) => <>{children}</>;

const BaseLayoutFooter = ({ children }: SlotProps) => <>{children}</>;

/**
 * Attach the 3 sub‐components to `BaseLayoutResolver` using Object.assign,
 * so TypeScript knows `BaseLayout.Top` & `BaseLayout.Footer` actually exist.
 */
export const BaseLayout: BaseLayoutComponent = Object.assign(
  BaseLayoutResolver,
  {
    Top: BaseLayoutTop,
    Content: BaseLayoutContent,
    Footer: BaseLayoutFooter
  }
);
