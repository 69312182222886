import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { creditApplicationModificationNotice } from '~/lib/form/credit-application-modification-notice';
import { loadFontBytes } from '~/lib/pdf';
import uploadFileAction from '../../../../actions/formSubmissions/uploadFileAction';

const generateAndUploadCreditApplicationModificationNoticeWorkflow = async ({
  t,
  dealershipId,
  name,
  ipAddress
}: {
  t: TFunction;
  dealershipId: string;
  name: string;
  ipAddress: string;
}) => {
  // Generate modification notice
  const modificationPdf = await generatePdf(
    creditApplicationModificationNotice(t),
    {
      dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
      name,
      ipAddress,
      deviceId: window.navigator.userAgent
    },
    false,
    undefined,
    loadFontBytes
  );
  const modificationPdfBlob = new Blob([modificationPdf]);

  return await uploadFileAction(
    { dealershipId },
    'pdf',
    'application/pdf',
    modificationPdfBlob
  );
};

export default generateAndUploadCreditApplicationModificationNoticeWorkflow;
