import { ReactFormApi } from '@tanstack/react-form';
import { ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, Select, TextInput } from '@thedealersconcierge/components';
import { TFunction } from 'i18next';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import stateOptions from '~/config/formSelectionOptions/stateOptions';

export type VehicleRegistrationFormType = {
  registrationName: string;
  registrationState: string;
  licensePlateNr: string;
};

export const ValidRegistrationFormSchema = (t: TFunction) => {
  return z.object({
    registrationName: z.string().min(1, t('Registration name is required')),
    registrationState: z.string().min(1, t('Registration State is required')),
    licensePlateNr: z.string().optional()
  });
};

const VehicleRegistrationForm: FC<{
  form: ReactFormApi<VehicleRegistrationFormType, ZodValidator>;
  isPrefilling: boolean;
  handlePrefillFromPlate: () => Promise<void>;
}> = ({ isPrefilling, form, handlePrefillFromPlate }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {/* Show the fields require for prefilling first */}
      <div className="w-full max-w-full grid grid-cols-8 gap-spacing-04 ">
        <div className="col-span-4 sm:col-span-3 flex">
          <form.Field name="registrationState">
            {(field) => {
              return (
                <Select
                  value={field.state.value}
                  label={t('Registration State')}
                  placeholder={t('Registration State')}
                  options={stateOptions}
                  disabled={isPrefilling}
                  required
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  onSelect={(e) => {
                    field.handleChange(e.value);
                  }}
                  backgroundType="LIGHT"
                  className="w-full"
                />
              );
            }}
          </form.Field>
        </div>

        <div className="col-span-4 sm:col-span-3 flex justify-center items-center">
          <form.Field name="licensePlateNr">
            {(field) => {
              return (
                <TextInput
                  name={field.name}
                  value={field.state.value}
                  label={t('License Plate Nr.')}
                  placeholder={t('License Plate Nr.')}
                  disabled={isPrefilling}
                  required
                  errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
                  onChange={(e) => {
                    field.handleChange(e);
                  }}
                  backgroundType="LIGHT"
                />
              );
            }}
          </form.Field>
        </div>

        <div className="col-span-8 sm:col-span-2 flex justify-center items-center">
          <div className="w-full flex flex-row">
            <Button
              variant="PRIMARY"
              label={t('Prefill')}
              onClick={void handlePrefillFromPlate}
              isLoading={isPrefilling}
              className="w-full"
            />
          </div>
        </div>
      </div>

      <form.Field name="registrationName">
        {(field) => {
          return (
            <TextInput
              name={field.name}
              value={field.state.value}
              label={t('Registration Name')}
              placeholder={t('Registration Name')}
              disabled={isPrefilling}
              required
              errorMessage={field.state.meta.errors?.at(0)?.toString()} // "errors" might be undefined contrary to types
              onChange={(e) => {
                field.handleChange(e);
              }}
              backgroundType="LIGHT"
            />
          );
        }}
      </form.Field>
    </Fragment>
  );
};

export default VehicleRegistrationForm;
