import {
  ChevronLeft,
  ChevronRight,
  Crop,
  DeleteOutline,
  DocumentScannerOutlined
} from '@mui/icons-material';
import { Button } from '@thedealersconcierge/components';
import { throttle } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentDetection } from '~/pages/scanner/_components/Scanner';
import { BaseLayout } from '~/pages/scanner/_components/ScreenBaseLayout';
import { ScreenModes } from './ScreenController';

export default function ReviewSingular({
  pages: items,
  setScreenMode,
  onDeleteByIndex,
  setOnEditIndex
}: {
  pages: DocumentDetection[];
  setScreenMode: (screenMode: ScreenModes) => void;
  onDeleteByIndex?: (index: number) => void;
  setOnEditIndex: (onEditIndex: number) => void;
}) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(items.length - 1);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const throttledSetActiveIndex = throttle((idx: number) => {
    setActiveIndex(idx);
  }, 200);

  const handleDeleteIndex = useCallback(
    (index: number) => {
      if (onDeleteByIndex) {
        const yes = window.confirm(
          t('Are you sure you want to delete this item?')
        );
        if (yes) {
          onDeleteByIndex(index);
          setActiveIndex((prev) => Math.min(prev, items.length - 2));
        }
        return;
      }
    },
    [onDeleteByIndex, items.length]
  );

  const handleRetake = useCallback(
    (index: number) => {
      if (onDeleteByIndex) {
        const yes = window.confirm(
          t('Are you sure you want to delete and retake this item?')
        );
        if (yes) {
          onDeleteByIndex(index);
          setActiveIndex((prev) => Math.min(prev, items.length - 2));
          setScreenMode('SCANNER');
          return;
        }
      }
    },
    [setScreenMode, onDeleteByIndex, items.length]
  );

  // Helper to scroll horizontally to a particular index
  const scrollToIndex = useCallback((idx: number) => {
    const container = scrollRef.current;
    if (!container) return;
    const containerWidth = container.clientWidth;
    container.scrollTo({
      left: containerWidth * idx,
      behavior: 'smooth'
    });
    throttledSetActiveIndex(idx);
  }, []);

  // Scroll to newest item when component mounts
  useEffect(() => {
    if (items.length > 0) {
      scrollToIndex(items.length - 1);
    }
  }, [items.length, scrollToIndex]);

  // Listen for manual scroll events to update activeIndex
  useEffect(() => {
    const container = scrollRef.current;
    if (!container) return;

    const onScroll = () => {
      if (!container) return;
      const scrollPos = container.scrollLeft;
      const containerWidth = container.clientWidth;
      const idx = Math.round(scrollPos / containerWidth);
      throttledSetActiveIndex(idx);
    };

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <BaseLayout>
      <BaseLayout.Top>
        <div className="flex flex-row justify-between items-center w-full px-spacing-04 py-spacing-02 border-b">
          <Button
            variant="GHOST"
            label={t('Done')}
            size="SMALL"
            onClick={() => {
              setScreenMode('SCANNER');
            }}
          />

          {/* Document counter in the center */}
          {items.length > 0 && (
            <div className="text-center font-medium">
              {activeIndex + 1} of {items.length}
            </div>
          )}

          <Button
            variant="GHOST"
            label={t('Overview')}
            size="SMALL"
            onClick={() => {
              setScreenMode('REVIEW_LIST');
            }}
          />
        </div>
      </BaseLayout.Top>

      <BaseLayout.Content>
        <div className="flex-1 relative">
          {/* Horizontal scroll container with snap */}
          <div
            ref={scrollRef}
            className="overflow-x-auto scroll-smooth snap-x snap-mandatory w-full h-full "
            style={{
              WebkitOverflowScrolling: 'touch',
              overscrollBehavior: 'contain'
            }}
          >
            <div className="flex h-full">
              {items.map((p, idx) => {
                const blob = p.cropped
                  ? new Blob([p.cropped], { type: 'image/png' })
                  : null;
                const url = blob ? URL.createObjectURL(blob) : undefined;

                return (
                  <div
                    key={idx}
                    className="
                      relative w-screen min-w-full h-full snap-center 
                      flex items-center justify-center px-spacing-03
                    "
                  >
                    <div className="w-full max-w-[90%] aspect-[1/1.414]">
                      {url && (
                        <img
                          draggable={false}
                          src={url}
                          alt={`Page ${idx + 1}`}
                          className="w-full h-full object-contain"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Left arrow */}
          {activeIndex > 0 && (
            <button
              onClick={() => {
                scrollToIndex(activeIndex - 1);
              }}
              className="
                absolute left-4 top-1/2 -translate-y-1/2 
                p-2 bg-white rounded-full shadow 
                flex items-center justify-center 
              "
            >
              <ChevronLeft />
            </button>
          )}

          {/* Right arrow */}
          {activeIndex < items.length - 1 && (
            <button
              onClick={() => {
                scrollToIndex(activeIndex + 1);
              }}
              className="
                absolute right-4 top-1/2 -translate-y-1/2 
                p-2 bg-white rounded-full shadow
                flex items-center justify-center
              "
            >
              <ChevronRight />
            </button>
          )}
        </div>
      </BaseLayout.Content>

      {/* Bottom Action Bar */}
      <BaseLayout.Footer>
        <div className="p-2 border-t flex justify-around">
          <div
            className="flex flex-col items-center text-primary-brand cursor-pointer px-spacing-03 py-spacing-02 gap-spacing-01"
            onClick={() => {
              handleRetake(activeIndex);
            }}
          >
            <DocumentScannerOutlined />
            <p className="label-02 font-normal">{t('Retake')}</p>
          </div>

          <div
            className="flex flex-col text-primary-brand items-center cursor-not-allowed px-spacing-03 py-spacing-02 gap-spacing-01"
            onClick={() => {
              setOnEditIndex(activeIndex);
              setScreenMode('CROP'); // Navigate to CropScreen
            }}
          >
            <Crop />
            <p className="label-02 font-normal">{t('Crop')}</p>
          </div>

          <div
            className="flex flex-col items-center text-primary-brand cursor-pointer px-spacing-03 py-spacing-02 gap-spacing-01"
            onClick={() => {
              handleDeleteIndex(activeIndex);
            }}
          >
            <DeleteOutline />
            <p className="label-02 font-normal">{t('Delete')}</p>
          </div>
        </div>
      </BaseLayout.Footer>
    </BaseLayout>
  );
}
