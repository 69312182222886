import { gqlMutationClient } from '~/lib/backend';

const recordConsentAction = async (email: string, phoneNumber: string) => {
  const resp = await gqlMutationClient({ dealershiplessAuth: true })({
    recordConsent: [
      {
        email,
        phoneNumber
      },
      {
        __typename: true,
        '...on GraphQLError': { message: true },
        '...on MutationRecordConsentSuccess': {
          data: { status: true }
        }
      }
    ]
  });

  if (!resp.recordConsent || resp.recordConsent.__typename === 'GraphQLError') {
    throw new Error(resp.recordConsent?.message ?? 'Unexpected error');
  }
};

export default recordConsentAction;
