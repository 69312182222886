import { validatePdf } from '@thedealersconcierge/lib/utils/pdfValidator';
import { ChangeEvent, FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { convertImageToPDF } from '~/lib/pdf';
import Spinner from '../Spinner';
import FileInputThumbnail from './FileInputThumbnail';

type FileInputProps = {
  setSelectedFiles: (files: { file: File; pdfIsEncrypted: boolean }[]) => void;
  selectedFiles: { file: File; pdfIsEncrypted: boolean }[];
  uploading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
};

const FileInput: FC<FileInputProps> = ({
  uploading = false,
  disabled = false,
  multiple = false,
  setSelectedFiles,
  selectedFiles = []
}) => {
  const { t } = useTranslation();
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);

      // Ensure that we don't have duplicates?
      const existingFilesMap = new Map<string, File>();
      selectedFiles.forEach((file) =>
        existingFilesMap.set(file.file.name, file.file)
      );

      // Clone it
      const updatedFiles = [...selectedFiles];

      // If the file of type image then convert it
      // Else it will do no thing
      for (const file of newFiles) {
        if (!existingFilesMap.has(file.name)) {
          if (file.type.startsWith('image/')) {
            const pdfFile = await convertImageToPDF(file);
            updatedFiles.push({
              file: pdfFile,
              // PDF files we generated are not encrypted
              pdfIsEncrypted: false
            });
          } else {
            // File is a PDF

            // Check validity
            const isValid = await validatePdf(file);

            // Skip invalid files
            if (isValid === 'INVALID') {
              toast.error(t('A selected file was corrupted'));
              continue;
            }

            // We only allow upload
            if (updatedFiles.some((f) => f.pdfIsEncrypted)) {
              toast.error(
                t(
                  'When uploading encrypted files, we only accept one at the time'
                )
              );
              break;
            }

            if (isValid === 'ENCRYPTED') {
              // Make sure that there was no existing files to add
              if (updatedFiles.length > 0) {
                toast.error(
                  t(
                    'When uploading encrypted files, we can only accept one at the time'
                  )
                );
                continue;
              }
              updatedFiles.push({ file, pdfIsEncrypted: true });
            }

            // Regular valid files
            if (isValid === 'VALID') {
              updatedFiles.push({ pdfIsEncrypted: false, file });
            }
          }
        }
      }

      setSelectedFiles(updatedFiles);
    }
  };

  const handleDelete = (fileName: string) => {
    setSelectedFiles(
      selectedFiles.filter((file) => file.file.name != fileName)
    );
  };

  return (
    <div className="bg-white rounded w-full mx-auto">
      <div className="relative flex flex-col items-center p-4 text-tertiary border-2 border-inactive hover:text-secondary hover:border-primary border-dashed rounded">
        {uploading && (
          <div className="flex flex-row gap-4 items-center">
            <Spinner className="w-12 aspect-square min-h-40" />
          </div>
        )}

        {!uploading && (
          <Fragment>
            <input
              accept="image/*, application/pdf"
              type="file"
              multiple={multiple}
              className="absolute inset-0 z-2 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
              onChange={(e) => void handleChange(e)}
              disabled={disabled}
            />
            {selectedFiles.length > 0 ? (
              <div className="flex flex-wrap justify-center gap-4 p-4">
                {selectedFiles.map((file, i) => (
                  <FileInputThumbnail
                    key={i}
                    file={file.file}
                    onDelete={() => {
                      handleDelete(file.file.name);
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-10 text-center">
                <p className="m-0">
                  {t('Drag your files here or click in this area.')}
                </p>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default FileInput;
