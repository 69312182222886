import { PrequalFormSchema } from '@thedealersconcierge/lib/codecs/schema/prequalApplication';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { DealershipIdentifier, info } from '~/globals';
import { gqlMutationClient } from '~/lib/backend';
import generateAndUploadPrequalPdfWorkflow from '~/workflows/formSubmissions/prequal/generateAndUploadPrequalPdfWorkflow';

const submitPreQualApplicationWorkflow = async ({
  t,
  values,
  dealershipIdentifier,
  dealershipName,
  ipAddress,
  signature,
  transactionId
}: {
  t: TFunction;
  values: {
    firstName: string;
    middleName: string;
    lastName: string;
    birthdate: string;
    socialSecurityNumber: string;
    street: string;
    apartmentDetails: string;
    zip: string;
    city: string;
    state: string;
    confirmNotAffectCreditScoreAndPrequalConsented: boolean;
    acknowledgesElectronicConsent: boolean;
    hasCommunicationsConsented: boolean;
  };
  dealershipName: string;
  dealershipIdentifier: DealershipIdentifier;
  ipAddress: string;
  signature: string;
  transactionId: string;
}) => {
  const formData: PrequalFormSchema = {
    state: values.state,
    suffix: '',
    middleName: values.middleName,
    birthdate: new Date(
      // The prequal schema works with dates. We have to make sure it's a UTC date
      `${values.birthdate}T00:00:00.000Z`
    ),
    dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
    name: `${values.firstName} ${values.lastName}`.trim(),
    ipAddress,
    deviceId: info.userAgent,
    street: values.street,
    apartmentDetails: values.apartmentDetails,
    city: values.city,
    zip: values.zip,
    signature,
    socialSecurityNumber: values.socialSecurityNumber,
    firstName: values.firstName,
    lastName: values.lastName
  };
  const prequalFile = await generateAndUploadPrequalPdfWorkflow(
    t,
    formData,
    dealershipName,
    dealershipIdentifier
  );
  const appl = await gqlMutationClient(dealershipIdentifier)({
    // Update the customer's social security number
    updateCustomer: [
      {
        transactionId,
        customer: {
          socialSecurityNumber: formData.socialSecurityNumber
        }
      },
      {
        __typename: true,
        '...on GraphQLError': { message: true },
        '...on MutationUpdateCustomerSuccess': { data: { status: true } }
      }
    ],

    // Submit the prequal application
    submitPrequalApplicationOnTransaction: [
      {
        transactionId,
        formData,
        signedUploadedDocumentId: prequalFile?.id ?? 'should-never-happen'
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationSubmitPrequalApplicationOnTransactionSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (
    !appl.submitPrequalApplicationOnTransaction ||
    appl.submitPrequalApplicationOnTransaction.__typename === 'GraphQLError'
  ) {
    throw new Error(
      appl.submitPrequalApplicationOnTransaction?.message ?? 'Unexpected error'
    );
  }
};

export default submitPreQualApplicationWorkflow;
