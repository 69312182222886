import { useQuery } from '@tanstack/react-query';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';
import ScrenController from '../_screens/ScreenController';

/**
 * We expect Consumer Scanner Page to be Opened in a new Tab with authenticated user
 * So we can get Transaction data here
 */
export default function ConsumerScannerPage() {
  const { transactionId } = useParams('/scanner/:transactionId');
  const { data: meData } = useQuery(meQuery());
  const navigate = useNavigate();
  const userId = meData?.me?.user?.id;

  // This should not happen
  // But just incase user not logged in then redirect to login screen
  if (!userId) {
    navigate('/signin');
    return;
  }

  return <ScrenController flowType="CONSUMER" transactionId={transactionId} />;
}
