import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button } from '@thedealersconcierge/components';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VehicleType } from '~/__generated__/backend/zeus';
import updateVehicleAction from '~/actions/vehicles/updateVehicleAction';
import GenericVehicleForm, {
  GenericVehicleFormType,
  ValidVehicleSchema
} from '~/components/forms/vehicle/GenericVehicleForm';
import VehicleRegistrationForm, {
  ValidRegistrationFormSchema,
  VehicleRegistrationFormType
} from '~/components/forms/vehicle/VehicleRegistrationForm';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import Spinner from '~/components/Spinner';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import { resetMeQuery } from '~/queries/meQuery';
import { resetTransactionQuery } from '~/queries/transactionQuery';
import {
  VehicleType as Vehicle,
  vehicleSelector
} from '~/querySelectors/vehicle';
import { Link, useNavigate, useParams } from '~/router';

const TradeVehicleInformationForm: FC<{
  tradeVehicle?: Vehicle;
  isSubmitting: boolean;
  setIsSubmitting: (v: boolean) => void;
}> = ({ tradeVehicle, isSubmitting, setIsSubmitting }) => {
  const { t } = useTranslation();
  const [isPrefilling, setIsPrefilling] = useState(false);
  const navigate = useNavigate();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/trade-vehicle/information'
  );
  const handleGoBack = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const genericVehicleForm = useForm<GenericVehicleFormType, ZodValidator>({
    defaultValues: {
      vin: tradeVehicle?.vin ?? '',
      model: tradeVehicle?.model ?? '',
      make: tradeVehicle?.make ?? '',
      year: tradeVehicle?.year ?? '',
      color: tradeVehicle?.color ?? '',
      bodyType: tradeVehicle?.bodyType ?? undefined,
      condition: tradeVehicle?.condition ?? undefined,
      mileage: tradeVehicle?.mileage?.toString() ?? ''
    },
    validators: {
      onSubmit: ValidVehicleSchema(t, VehicleType.TRADE)
    },
    validatorAdapter: zodValidator()
  });
  const vehicleRegistrationForm = useForm<
    VehicleRegistrationFormType,
    ZodValidator
  >({
    defaultValues: {
      registrationName: tradeVehicle?.registrationName ?? '',
      registrationState: tradeVehicle?.registrationState ?? '',
      licensePlateNr: ''
    },
    validators: {
      onSubmit: ValidRegistrationFormSchema(t)
    },
    validatorAdapter: zodValidator()
  });

  const handlePrefillFromPlate = async () => {
    const stateForPrefilling =
      vehicleRegistrationForm.getFieldValue('registrationState');
    const plateForPrefilling =
      vehicleRegistrationForm.getFieldValue('licensePlateNr');
    if (!stateForPrefilling || !plateForPrefilling) {
      return;
    }
    try {
      setIsPrefilling(true);
      const prefillValues = await gqlMutationClient({ dealershipSlug })({
        prefillLicensePlate: [
          {
            state: stateForPrefilling,
            licensePlate: plateForPrefilling
          },
          {
            __typename: true,
            '...on MutationPrefillLicensePlateSuccess': {
              data: {
                make: true,
                model: true,
                year: true,
                trim: true,
                vin: true
              }
            },
            '...on GraphQLError': {
              message: true
            }
          }
        ]
      });

      if (prefillValues.prefillLicensePlate?.__typename === 'GraphQLError') {
        toast.error('Could not fetch prefill values');
        return;
      }

      const values = prefillValues.prefillLicensePlate?.data;

      genericVehicleForm.setFieldValue('vin', values?.vin ?? '');
      genericVehicleForm.setFieldValue('make', values?.make ?? '');
      genericVehicleForm.setFieldValue('model', values?.model ?? '');
      genericVehicleForm.setFieldValue('year', values?.year ?? '');
    } finally {
      setIsPrefilling(false);
    }
  };

  /**
   * This will Either Create or Update the vehicle data
   */
  const submitVehicleInformation = async () => {
    try {
      // We need to check this explicitly since we combine sumit of multiple forms
      const vehicleRegistrationFormValid =
        !vehicleRegistrationForm.validateSync('submit').hasErrored;
      const genericVehicleFormValid =
        !genericVehicleForm.validateSync('submit').hasErrored;

      if (!vehicleRegistrationFormValid || !genericVehicleFormValid) return;

      setIsSubmitting(true);
      const vehicleRegistration = vehicleRegistrationForm.state.values;
      const genericVehicleInformation = genericVehicleForm.state.values;

      // If Vehicle exists then do update
      if (tradeVehicle?.id) {
        await updateVehicleAction(
          { dealershipSlug },
          transactionId,
          VehicleType.TRADE,
          tradeVehicle?.id ?? 'no-way',
          {
            registrationName: vehicleRegistration.registrationName,
            registrationState: vehicleRegistration.registrationState,
            ...genericVehicleInformation,
            mileage: parseFloat(genericVehicleInformation.mileage) // It is safe to do this since mileage is required
          }
        );

        await Promise.all([
          resetMeQuery(),
          resetTransactionQuery(transactionId, dealershipSlug)
        ]);

        navigate(
          '/dashboard/:dealershipSlug/:transactionId/trade-vehicle/registrationCard',
          {
            params: { transactionId, dealershipSlug }
          }
        );
      } else {
        // Create Trade vehicle if it is not exists create one
        const resp = await gqlMutationClient({ dealershipSlug })({
          createVehicle: [
            {
              transactionId: transactionId,
              vehicleType: VehicleType.TRADE,
              vehicle: {
                registrationName: vehicleRegistration.registrationName,
                registrationState: vehicleRegistration.registrationState,
                ...genericVehicleInformation,
                mileage: parseFloat(genericVehicleInformation.mileage) // It is safe to do this since mileage is required
              }
            },
            {
              __typename: true,
              '...on GraphQLError': {
                message: true
              },
              '...on MutationCreateVehicleSuccess': {
                data: {
                  status: true,
                  id: true
                }
              }
            }
          ]
        });

        if (resp.createVehicle?.__typename === 'MutationCreateVehicleSuccess') {
          await Promise.all([
            resetMeQuery(),
            resetTransactionQuery(
              transactionId ?? 'should-never-happen',
              dealershipSlug
            )
          ]);

          navigate(
            '/dashboard/:dealershipSlug/:transactionId/trade-vehicle/registrationCard',
            {
              params: { transactionId, dealershipSlug }
            }
          );
        } else {
          throw new Error(resp.createVehicle?.message);
        }
      }
    } catch (error) {
      captureException(error);
      console.error('Error', error);
      if (error instanceof Error && error.message) {
        toast.error(error.message);
      } else {
        toast.error('Failed to save vehicle data');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <form
        className="flex flex-col overflow-y-auto max-w-full space-y-8 overflow-x-hidden"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void submitVehicleInformation();
        }}
      >
        {/* This entire component should use a FormContainer and the mandated way to do layout
            Feel free to lift it when working on it.
        */}
        <VehicleRegistrationForm
          form={vehicleRegistrationForm}
          handlePrefillFromPlate={handlePrefillFromPlate}
          isPrefilling={isPrefilling}
        />

        <GenericVehicleForm
          form={genericVehicleForm}
          isSubmitting={isSubmitting}
          vehicleType={VehicleType.TRADE}
          dataTestId="vehicle-for-trade"
        />

        <div className="flex flex-row justify-between">
          <Button
            variant="SECONDARY"
            disabled={isSubmitting}
            onClick={handleGoBack}
            label={t('Back')}
          />

          <Button
            isLoading={isSubmitting}
            label={t('Next')}
            type="submit"
            onClick={() => {
              void submitVehicleInformation();
            }}
          />
        </div>
      </form>
    </Fragment>
  );
};

const TradeVehicleInformationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/trade-vehicle/information'
  );
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const { data, isLoading } = useQuery({
    queryKey: ['transaction', transactionId, 'tradeVehicle'],
    queryFn: async () =>
      gqlQueryClient({ dealershipSlug })({
        transaction: [
          {
            id: transactionId
          },
          {
            tradeVehicle: vehicleSelector
          }
        ]
      })
  });

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Trade Registration')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
        totalSteps={4}
        currentStep={1}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col items-center overflow-y-auto px-6 ">
        <div className="flex flex-col max-w-screen-md py-10 space-y-12 justify-between w-full">
          <div className="space-y-8 md:space-y-16">
            <div className="space-y-4">
              <h2>{t('Step 1: Vehicle Information')}</h2>

              <p>
                {t(
                  'Please fill in or verify the information in the fields below.'
                )}
              </p>
            </div>

            {isLoading && (
              <div className="flex flex-1 items-center justify-center">
                <Spinner />
              </div>
            )}

            {!isLoading && (
              <TradeVehicleInformationForm
                tradeVehicle={data?.transaction?.tradeVehicle}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeVehicleInformationPage;
