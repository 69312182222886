import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, Tooltip } from '@thedealersconcierge/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VehicleType } from '~/__generated__/backend/zeus';
import updateVehicleAction from '~/actions/vehicles/updateVehicleAction';
import Header from '~/components/Header';
import Spinner from '~/components/Spinner';
import GenericVehicleForm, {
  GenericVehicleFormType,
  ValidVehicleSchema
} from '~/components/forms/vehicle/GenericVehicleForm';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import { gqlMutationClient } from '~/lib/backend';
import { queryClient } from '~/main';
import { resetMeQuery } from '~/queries/meQuery';
import transactionQuery, {
  resetTransactionQuery
} from '~/queries/transactionQuery';
import { Link, useNavigate, useParams } from '~/router';

const PurchaseVehiclePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/purchase-vehicle'
  );
  const { data, isLoading } = useQuery(
    transactionQuery(transactionId, dealershipSlug)
  );
  const vehicle = data?.transaction?.vehicle;

  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };

  const form = useForm<GenericVehicleFormType, ZodValidator>({
    defaultValues: {
      stockNumber: vehicle?.stockNumber ?? '',
      lifeCycleStage: vehicle?.lifeCycleStage ?? undefined,
      vin: vehicle?.vin ?? '',
      make: vehicle?.make ?? '',
      model: vehicle?.model ?? '',
      year: vehicle?.year ?? '',
      trim: vehicle?.trim ?? '',
      mileage: vehicle?.mileage?.toString() ?? ''
    },
    validators: {
      onSubmit: ValidVehicleSchema(t, VehicleType.PURCHASE)
    },
    validatorAdapter: zodValidator(),
    onSubmit: async (form) => {
      try {
        const values = form.value;

        // Update when vehicle already exists
        if (vehicle?.id) {
          setIsSubmitting(true);

          await updateVehicleAction(
            { dealershipSlug },
            transactionId,
            VehicleType.PURCHASE,
            vehicle.id,
            {
              stockNumber: values.stockNumber,
              lifeCycleStage: values.lifeCycleStage,
              vin: values.vin,
              make: values.make,
              model: values.model,
              year: values.year,
              trim: values.trim,
              mileage: parseFloat(values.mileage) // It is safe to do this since mileage is required
            }
          );

          await Promise.all([
            resetMeQuery(),
            queryClient.resetQueries({
              queryKey: ['transaction', transactionId]
            })
          ]);

          // Notify success & redirect
          toast.success('Successfully updated purchase vehicle', {
            autoClose: 2000, // 2 seconds
            onClose: () => {
              navigate('/dashboard/:dealershipSlug/:transactionId', {
                params: { transactionId, dealershipSlug }
              });
            }
          });
        } else {
          // Create a new Vehicle
          const vehicle = await gqlMutationClient({ dealershipSlug })({
            createVehicle: [
              {
                transactionId: transactionId,
                vehicleType: VehicleType.PURCHASE,
                vehicle: {
                  stockNumber: values.stockNumber,
                  lifeCycleStage: values.lifeCycleStage,
                  vin: values.vin,
                  make: values.make,
                  model: values.model,
                  year: values.year,
                  trim: values.trim,
                  mileage: parseFloat(values.mileage) // It is safe to do this since mileage is required
                }
              },
              {
                __typename: true,
                '...on GraphQLError': {
                  message: true
                },
                '...on MutationCreateVehicleSuccess': {
                  data: {
                    status: true,
                    id: true
                  }
                }
              }
            ]
          });
          if (
            vehicle.createVehicle?.__typename === 'MutationCreateVehicleSuccess'
          ) {
            await Promise.all([
              resetMeQuery(),
              resetTransactionQuery(
                transactionId ?? 'should-never-happen',
                dealershipSlug
              )
            ]);

            // Notify success & redirect
            toast.success(t('Successfully added purchase vehicle'), {
              autoClose: 2000, // 2 seconds
              onClose: () => {
                navigate('/dashboard/:dealershipSlug/:transactionId', {
                  params: { transactionId, dealershipSlug }
                });
              }
            });
          } else {
            throw new Error(vehicle.createVehicle?.message);
          }
        }
      } catch (error) {
        captureException(error);
        console.error(error);

        if (error instanceof Error && error.message) {
          toast.error(error.message);
        } else {
          toast.error(
            t(
              'Cannot add purchase vehicle, Please try again or contact support.'
            )
          );
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Vehicle for Purchase')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center py-2">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div
        className="flex flex-col items-center overflow-y-scroll px-6"
        data-test-id={'vehicle-for-purchase-body'}
      >
        <div className="flex flex-col max-w-screen-md py-6 md:py-10 space-y-8 md:space-y-16 w-full">
          <div className="space-y-5">
            <h2>{t('Vehicle for Purchase')}</h2>

            <p>
              {t(
                'Please fill in or verify the information in the fields below.'
              )}
            </p>
          </div>

          {isLoading && (
            <div className="flex w-full aspect-square justify-center items-center relative">
              <Spinner className="w-10 aspect-square" />
            </div>
          )}

          {!isLoading && (
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
              data-test-id={'vehicle-for-purchase-form'}
            >
              <GenericVehicleForm
                form={form}
                isSubmitting={isSubmitting}
                vehicleType={VehicleType.PURCHASE}
                dataTestId={`vehicle-for-purchase`}
              />

              <div className="flex flex-row justify-between pt-4">
                <Button
                  variant="SECONDARY"
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleCancel}
                  dataTestId="vehicle-for-purchase-cancel-button"
                  label={t('Cancel')}
                />

                <Tooltip
                  anchor={
                    <div>
                      <Button
                        type="submit"
                        disabled={vehicle?.updatedByDealership || isSubmitting}
                        dataTestId="vehicle-for-purchase-submit-button"
                        label={t('Save')}
                      />
                    </div>
                  }
                  hide={!vehicle?.updatedByDealership}
                  content={
                    <p>
                      {t(
                        'Data has been entered by dealership and cannot be edited'
                      )}
                    </p>
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseVehiclePage;
