import ScanbotSDK from 'scanbot-web-sdk';
import { DocumentDetection } from '../_components/Scanner';

const licenseKey =
  'DgDhJ2Pkq761kRaRk2J+fzPgBrLPHR' +
  'w5jH4PKMWxFxMdAVqfKjCGLpL45Iwk' +
  'I2yZDsB48+gbaztU+wJyfVDQa6AeUb' +
  'wCsRlRg11zLRUk1pKQbFTdHCP/y+Fu' +
  'ueuKsazeCAqPxA5WC6L2l3WZ4wzH2n' +
  'wvHDWZTT0x0ryt6AFok1qdBGwdDjuf' +
  '85VoEAI0tCLCDvngbnUqeIvH9wU1YF' +
  'R6f1c2v2t9KKF8n+9Cg6aBe6qVWfut' +
  'YYRWNFBfUlMcgdVTkrXES+ET2LuvPI' +
  'PVtQDHEYPl0jGSmSHOCxP6Jb6hwSXv' +
  'xguzcnO6kmE8EMak4I4/ARS18IjGru' +
  'UPLvU99mmpaw==\nU2NhbmJvdFNESw' +
  'pteXRkYy5uZXR8bG9jYWxob3N0fGFw' +
  'cC5zdGFnaW5nLm15dGRjLm5ldHxhcH' +
  'AuZGV2Lm15dGRjLm5ldHxhcHAubXl0' +
  'ZGMubmV0fGRhc2hib2FyZC5zdGFnaW' +
  '5nLm15dGRjLm5ldHxkYXNoYm9hcmQu' +
  'ZGV2Lm15dGRjLm5ldHxkYXNoYm9hcm' +
  'QubXl0ZGMubmV0fGJhY2tvZmZpY2Uu' +
  'c3RhZ2luZy5teXRkYy5uZXR8YmFja2' +
  '9mZmljZS5kZXYubXl0ZGMubmV0fGJh' +
  'Y2tvZmZpY2UubXl0ZGMubmV0CjE3Nj' +
  'kzODU1OTkKNTkwCjg=\n';

let scanbotSdk: ScanbotSDK | undefined;
export const getScanbotSdk = async () => {
  if (scanbotSdk) {
    return scanbotSdk;
  }
  scanbotSdk = await ScanbotSDK.initialize({
    licenseKey: licenseKey
  });
  return scanbotSdk;
};

/**
 * Generates a PDF from an array of pages.
 *
 * @param pages - An array of page objects from ScanSDK
 */
export const generatePdfBlobFromScannedDocuments = async (
  pages: DocumentDetection[]
): Promise<Blob> => {
  // Initialize the Scanbot SDK and start a new PDF with the desired options.
  const sdk = await getScanbotSdk();
  const generator = await sdk.beginPdf({
    standardPaperSize: 'A4',
    pageDirection: 'PORTRAIT',
    pageFit: 'FIT_IN',
    dpi: 72,
    jpegQuality: 80,
    resample: false
  });

  // Process each page and add it to the PDF generator.
  for (const page of pages) {
    if (page.cropped) {
      // Create a new ArrayBuffer and copy the cropped data into it.
      const properArrayBuffer = new ArrayBuffer(page.cropped.byteLength);
      new Uint8Array(properArrayBuffer).set(page.cropped);
      await generator.addPage(properArrayBuffer);
    }
  }

  const pdfContents = await generator.complete();
  const blob = new Blob([pdfContents], { type: `application/pdf` });
  return blob;
};
