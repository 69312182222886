import { authStateAtom, isCognitoAuth } from '~/state/auth';
import {
  FetchFunction,
  GraphQLResponse,
  Thunder,
  ZeusScalars
} from '../__generated__/backend/zeus';
import config from '../config';
import { getJwt } from './auth';
import store from './store';

const scalars = ZeusScalars({
  JSON: {
    encode: (e: unknown) => JSON.stringify(e),
    decode: (e: unknown) =>
      // The JSON fields in a response can be sent as objects and not strings.
      // Therefore, we have to check the type before trying to parse a JSON string.
      // If it's not a string, we are just returning the object
      (typeof e === 'string' ? JSON.parse(e) : e) as unknown
  },
  DateTime: {
    decode: (e: unknown) => new Date(e as string),

    // It inserts it verbatim, not with enclosing `"` for strings
    encode: (e: unknown) => `"${(e as Date).toISOString()}"`
  }
});

/**
 * We use this structure, as it is incredibly difficult to ensure that we sign in to the right
 * dealership, so we need to force the users of the function to think about it
 */
type DealershipPart =
  | {
      /**
       * ID of the dealership to carry dealership-indexed operations out on
       *
       * Only one of this or dealershipId is needed
       */
      dealershipSlug: string;
    }
  | {
      /**
       * ID of the dealership to carry dealership-indexed operations out on
       *
       * Only one of this or dealershipSlug is needed
       */
      dealershipId: string;
    }
  | {
      /**
       * This is a marker that we don't provide dealership
       */
      dealershiplessAuth: true;
    };

type Options = {
  /**
   * Throws if the graphQL errors out
   */
  throwOnError?: boolean;
} & DealershipPart;
const handleFetchResponse = async (
  response: Response
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    const msg = await response.text();
    throw new Error(msg);
  }
  return await response.json();
};

export const getAuthHeaders = async (
  dealershipId?: string,
  dealershipSlug?: string
): Promise<HeadersInit> => {
  const authJwt = store.instance.get(authStateAtom);
  const authHeaders: HeadersInit = {};

  const jwt = isCognitoAuth(authJwt) ? await getJwt() : authJwt?.jwt;

  if (jwt) {
    authHeaders['Authorization'] = `Bearer ${jwt}`;
  }

  if (dealershipId) {
    authHeaders['DealershipId'] = dealershipId;
  }

  if (dealershipSlug) {
    authHeaders['DealershipSlug'] = dealershipSlug;
  }

  return authHeaders;
};

const fetchFunction: (options: Options) => FetchFunction =
  (options) =>
  async (query, variables: Record<string, unknown> = {}) => {
    if ('dealershipId' in options) {
      options.dealershipId;
    }

    const authHeaders = await getAuthHeaders(
      'dealershipId' in options ? options.dealershipId : undefined,
      'dealershipSlug' in options ? options?.dealershipSlug : undefined
    );

    const response = await fetch(config.backendUrl, {
      body: JSON.stringify(
        {
          query,
          variables: variables
        },
        (_, value) => {
          return value;
        }
      ),
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json'
      }
    });

    const gqlResp = await handleFetchResponse(response);

    if (gqlResp.errors) {
      if (options?.throwOnError) {
        throw new Error(JSON.stringify(gqlResp.errors));
      } else {
        console.error(gqlResp.errors);
      }
    }

    return gqlResp.data;
  };

/**
 * The query client for the graphQL backend
 *
 * NOTE: Remember to add the dealershipId or dealershipSlug in the options if the operation is dealership specific (Most operations _are_)
 *
 * Use the mutation client to carry out mutations
 */
export const gqlQueryClient = (options: Options) =>
  Thunder(fetchFunction(options), {
    scalars
  })('query');

/**
 * The mutation client for the graphQL backend
 *
 * NOTE: Remember to add the dealershipId or dealershipSlug in the options if the operation is dealership specific (Most operations _are_)
 *
 * Use the query client to carry out queries
 */
export const gqlMutationClient = (options: Options) =>
  Thunder(fetchFunction(options), {
    scalars
  })('mutation');
