import { CheckCircle } from '@mui/icons-material';

export default function DonePag() {
  return (
    <div className="w-dvw h-dvh flex justify-center items-center">
      <div className="flex flex-col items-center space-y-4">
        <div className="w-[50px] h-[50px]">
          <CheckCircle
            style={{ width: '100%', height: '100%' }}
            className="icon-success"
          />
        </div>

        <h3 className="heading-03 text-center">Your document were uploaded</h3>

        <p className="body-01 text-center">
          Scan a new QR code to upload more documents.
        </p>
      </div>
    </div>
  );
}
