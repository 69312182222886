import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Scanner, {
  DocumentDetection
} from '~/pages/scanner/_components/Scanner';
import CapturePreview from './CapturePreview';
import CropScreen from './CropScreen';
import Landing from './Landing';
import ReorderScreen from './ReorderScreen';
import { ReviewConsumer } from './ReviewConsumer';
import ReviewDashboard from './ReviewDashboard';
import ReviewSingular from './ReviewSingular';
import ScannerScreen from './ScannerScreen';

export type ScreenModes =
  | 'LANDING'
  | 'SCANNER'
  | 'CAPTURE_PREVIEW'
  | 'REVIEW_LIST'
  | 'FINAL_REVIEW'
  | 'REVIEW_SINGULAR'
  | 'CROP';

export type ScannerFlowType = 'CONSUMER' | 'DASHBOARD' | 'KIOSK';

/**
 * This component act the as controller for other scanner screen, everything happen in the scanner is decided here
 * Ideally we do not fetch any external data here e.g (transaction, meQuery) all data input needs to be passed down
 * from the parent component
 */
export default function ScrenController({
  flowType,
  transactionId,
  temporaryToken
}: {
  flowType: ScannerFlowType;
  transactionId: string;
  temporaryToken?: string;
}) {
  const [pages, setPages] = useState<DocumentDetection[]>([]); // Pages / documents that user decided to keep
  const [draftPage, setDraftPage] = useState<DocumentDetection | null>(null); // We use null to satisfy the Scanner result
  const [screenMode, setScreenMode] = useState<ScreenModes>('LANDING');
  const [onEditIndex, setOnEditIndex] = useState<number | undefined>(); // Shared index for current edited file

  const handleSetScreenMode = (screenMode: ScreenModes) => {
    setScreenMode(screenMode);
  };

  const { t } = useTranslation();

  // The text hints you pass down into the Scanner
  const text = useMemo(
    () => ({
      hint: {
        OK: t('Capturing your document... Please do not move the camera.'),
        OK_SmallSize: t('The document is too small. Try moving closer.'),
        OK_BadAngles: t('This is a bad angle. Hold the device straight.'),
        OK_BadAspectRatio: t('Rotate the device so the document fits better.'),
        OK_OffCenter: t(
          'Try holding the device at the center of the document.'
        ),
        Error_NothingDetected: t('Please hold the device over a document.'),
        Error_Brightness: t('It is too dark. Try turning on a light.'),
        Error_Noise: t('Move the doc to a clear surface.')
      }
    }),
    [t]
  );

  const MemoizedScanner = useMemo(() => {
    return (
      <Scanner
        text={text}
        onNewPage={(detected) => {
          // Handle new capture
          setDraftPage(detected);
          setScreenMode('CAPTURE_PREVIEW');
        }}
      />
    );
  }, [text]);

  switch (screenMode) {
    case 'SCANNER':
      return (
        <ScannerScreen
          setScreenMode={handleSetScreenMode}
          pages={pages}
          scanner={MemoizedScanner}
        />
      );

    case 'CAPTURE_PREVIEW':
      if (!draftPage || !draftPage.cropped) {
        setScreenMode('SCANNER');
        return;
      }

      return (
        <CapturePreview
          draftPage={draftPage}
          pages={pages}
          setScreenMode={handleSetScreenMode}
          afterPreviewCallback={() => {
            if (draftPage) {
              setPages((prev) => [...prev, draftPage]);
            }
            setDraftPage(null);
            setScreenMode('SCANNER');
          }}
          previewTimeInMs={2000}
        />
      );

    case 'REVIEW_LIST':
      return (
        <ReorderScreen
          pages={pages}
          setScreenMode={handleSetScreenMode}
          onDeleteAll={() => {
            setPages([]);
          }}
          onDeleteByIndex={(index) => {
            setPages((prev) => prev.filter((_, i) => i !== index));
          }}
        />
      );
    case 'FINAL_REVIEW':
      if (flowType === 'DASHBOARD') {
        if (!temporaryToken) {
          setScreenMode('SCANNER');
          return;
        }

        return (
          <ReviewDashboard
            transactionId={transactionId}
            submissionToken={temporaryToken}
            pages={pages}
            onNavBack={() => {
              setScreenMode('SCANNER');
            }}
          />
        );
      }

      if (flowType === 'CONSUMER' || flowType === 'KIOSK') {
        return (
          <ReviewConsumer
            transactionId={transactionId}
            submissionToken={temporaryToken}
            pages={pages}
            onNavBack={() => {
              setScreenMode('SCANNER');
            }}
          />
        );
      }
      break;

    case 'REVIEW_SINGULAR':
      return (
        <ReviewSingular
          pages={pages}
          setScreenMode={handleSetScreenMode}
          onDeleteByIndex={(index) => {
            setPages((prev) => prev.filter((_, i) => i !== index));
          }}
          setOnEditIndex={setOnEditIndex}
        />
      );

    case 'CROP':
      if (onEditIndex === undefined) {
        setScreenMode('REVIEW_LIST');
        return;
      }
      return (
        <CropScreen
          pages={pages}
          setScreenMode={handleSetScreenMode}
          setPages={(updatedPages: DocumentDetection[]) => {
            setPages(updatedPages);
          }}
          activeIndex={onEditIndex}
        />
      );

    // Landing and Default Screen are the same one
    case 'LANDING':
    default:
      return <Landing setScreenMode={handleSetScreenMode} />;
  }
}
