import { useQuery } from '@tanstack/react-query';
import { Button, Spinner } from '@thedealersconcierge/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoutAction from '~/actions/logoutAction';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentsIcon from '~/components/icons/DocumentsIcon';
import transactionQuery from '~/queries/transactionQuery';
import { Link, useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from '../_components/ConsumerDashboardHeader';
import OpenScannerModel from './_components/OpenScannerModal';

const AdditionalDocumentUploadModeSelector = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/additional-documents'
  );
  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = () => {
    setLoggingOut(true);
    logoutAction();
  };

  const { data: transactionQueryData, isLoading: loadingTransactionData } =
    useQuery(transactionQuery(transactionId, dealershipSlug));
  const transaction = transactionQueryData?.transaction;

  // Automatically redirect to upload page if the dealership not enabled scanner
  useEffect(() => {
    if (
      !loadingTransactionData &&
      !transaction?.dealership?.hasEnabledScanner
    ) {
      navigate(
        '/dashboard/:dealershipSlug/:transactionId/additional-documents/upload',
        {
          params: {
            dealershipSlug: dealershipSlug,
            transactionId: transactionId
          }
        }
      );
    }
  }, [loadingTransactionData]);

  return (
    <div className="flex flex-col h-dvh w-full">
      <ConsumerDashboardHeader
        leftElement={
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-spacing-05"
          >
            <div className="relative">
              <ChevronRightIcon className="flex w-spacing-05 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        }
        centerElement={
          <div className="flex flex-row space-x-spacing-02 items-center">
            <div className="relative">
              <DocumentsIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <p className="heading-03 md:heading-emphasized-02 text-center">
              {t('Add Documents')}
            </p>
          </div>
        }
        rightElement={
          <Button
            variant="GHOST"
            size="SMALL"
            onClick={() => {
              handleLogout();
            }}
            disabled={loggingOut}
            className="!p-0"
            label={t('Logout')}
          />
        }
        showBorder
      />
      <div className="flex flex-col pt-spacing-04 space-y-spacing-05 w-full items-center overflow-y-auto ">
        {loadingTransactionData && (
          <div className="flex w-full max-w-screen-md aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" size="LARGE" color="GREY" />
          </div>
        )}

        {!loadingTransactionData && (
          <div className="flex flex-col space-y-spacing-04 w-full items-center justify-start mt-spacing-09 md:mt-spacing-10 h-dvh">
            <div className="w-full px-spacing-06 md:px-0 max-w-screen-md">
              <p className="heading-emphasized-02 text-center">
                {t('How Would You Like to Upload Your Documents?')}
              </p>

              {/* Cards */}
              <div className="grid md:grid-cols-2 items-center justify-center gap-spacing-08 mt-spacing-09">
                {/* Upload Card */}
                <div className="w-full p-spacing-05 bg-primary border border-secondary rounded-radius-02">
                  <div className="flex flex-col items-center text-center gap-spacing-06">
                    <p className="heading-02 font-semibold">
                      {t('Upload from Device')}
                    </p>
                    <p className="text-secondary body-01">
                      {t(
                        'Easily upload documents from your computer, phone, or tablet.'
                      )}
                    </p>
                    <Button
                      label={t('Choose File')}
                      onClick={() => {
                        navigate(
                          '/dashboard/:dealershipSlug/:transactionId/additional-documents/upload',
                          {
                            params: {
                              dealershipSlug: dealershipSlug,
                              transactionId: transactionId
                            }
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                {/* Scan Card */}
                {transaction?.dealership?.hasEnabledScanner && (
                  <div className="w-full p-spacing-05 bg-primary border border-secondary rounded-radius-02">
                    <div className="flex flex-col items-center text-center gap-spacing-06">
                      <p className="heading-02 font-semibold">
                        {t('Scan with Camera')}
                      </p>
                      <p className="text-secondary body-01">
                        {t(
                          "Use your device's camera to scan and detect document instantly"
                        )}
                      </p>

                      {/* This will either return a Button on consumer app ans QR Code on kiosk mode */}
                      <OpenScannerModel
                        transactionId={transactionId}
                        dealershipIdentifier={{ dealershipSlug }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AdditionalDocumentUploadModeSelector;
