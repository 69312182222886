import { Button } from '@thedealersconcierge/components';
import { base64ToBlob } from '@thedealersconcierge/lib/utils/files';
import classNames from 'classnames';
import { Fragment, useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';
import { z } from 'zod';
import logoutAction from '~/actions/logoutAction';
import { A4PageOverlay } from '~/components/Overlays';
import Spinner from '~/components/Spinner';
import CameraIcon from '~/components/icons/CameraIcon';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentsIcon from '~/components/icons/DocumentsIcon';
import { gqlMutationClient } from '~/lib/backend';
import { convertImageToPDF } from '~/lib/pdf';
import { Link, useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from '../../../_components/ConsumerDashboardHeader';

function calculateOverlaySize(
  imageWidth: number,
  imageHeight: number,
  overlayHeightPercentage = 0.9
) {
  const aspectRatio = 21 / 29.7; // Width to height ratio of A4 Paper

  // Calculate the overlay height based on the desired height percentage
  const overlayHeight = imageHeight * overlayHeightPercentage;
  const overlayWidth = overlayHeight * aspectRatio;

  // Calculate the position of the overlay
  const overlayX = (imageWidth - overlayWidth) / 2;
  const overlayY = (imageHeight - overlayHeight) / 2;

  return { overlayWidth, overlayHeight, overlayX, overlayY };
}

const AddAdditionalDocumentTypes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionId, documentType, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/additional-documents/upload/:documentType'
  );
  // TODO: Must remove state usage here
  const documentName = z.string().parse(location.state?.documentName);

  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = () => {
    setLoggingOut(true);
    logoutAction();
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Camera States
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const webcamRef = useRef<Webcam | null>(null);
  const [capture, setCapture] = useState<string | undefined>(undefined);

  const resetCapture = useCallback(() => {
    setCapture(undefined);
  }, [setCapture]);

  const handleRetake = useCallback(() => {
    resetCapture();
    setCameraLoaded(false);
  }, [resetCapture]);

  const handleCameraLoaded = () => {
    setCameraLoaded(true);
  };

  const takePicture = useCallback(async () => {
    if (webcamRef.current && !isSubmitting) {
      const screenshot = webcamRef.current.getScreenshot();
      if (screenshot) {
        const image = new Image();
        image.src = screenshot;

        try {
          await new Promise((resolve, reject) => {
            image.onload = resolve;
            image.onerror = reject;
          });

          // Will crop the image to A4 size
          const { overlayWidth, overlayHeight, overlayX, overlayY } =
            calculateOverlaySize(image.width, image.height);
          const canvas = document.createElement('canvas');
          canvas.width = overlayWidth;
          canvas.height = overlayHeight;
          const ctx = canvas.getContext('2d');

          if (!ctx) return;
          ctx.drawImage(
            image,
            overlayX,
            overlayY,
            overlayWidth,
            overlayHeight,
            0,
            0,
            overlayWidth,
            overlayHeight
          );

          const croppedImage = canvas.toDataURL('image/png');
          setCapture(croppedImage);
        } catch (error) {
          console.error('Error loading image for cropping:', error);
          setCapture(undefined);
        }
      } else {
        setCapture(undefined);
      }
    }
  }, [webcamRef, isSubmitting]);

  const handleUpload = async () => {
    if (!capture) return;

    setIsSubmitting(true);

    // Convert Capture to File and then to PDF
    const imageBase64 = capture?.split(',')[1];
    const imageBlob = base64ToBlob(imageBase64, 'image/png');
    const imageFile = new File(
      [imageBlob],
      `additional_document_${documentType}`,
      {
        type: 'image/png'
      }
    );

    // Convert Image to PDF
    const fileToUpload = await convertImageToPDF(imageFile);
    const fileExt = fileToUpload.name.split('.').pop() ?? 'unknown';
    const mimeType = fileToUpload.type || 'application/octet-stream';

    const pictureUpload = await gqlMutationClient({ dealershipSlug })({
      makeUpload: [
        {
          mimeType: mimeType,
          fileExt: fileExt
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationMakeUploadSuccess': {
            data: {
              fileId: true,
              uploadUrl: true
            }
          }
        }
      ]
    });

    const optimisticResp =
      pictureUpload.makeUpload?.__typename !== 'GraphQLError'
        ? pictureUpload.makeUpload?.data
        : undefined;

    if (!optimisticResp) return;

    if (optimisticResp.uploadUrl) {
      const res = await fetch(optimisticResp.uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': mimeType,
          'Content-Length': fileToUpload.size.toString()
        },
        body: fileToUpload
      });

      if (res.status !== 200) {
        throw new Error('File failed to upload, contact admin');
      }
    }

    await gqlMutationClient({ dealershipSlug })({
      uploadAdditionalDocuments: [
        {
          category: documentType,
          transactionId: transactionId,
          uploadedDocumentId: optimisticResp.fileId ?? 'should-never-happen',
          title: documentName,

          // Phone captures are not encrypted
          pdfIsEncrypted: false
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationUploadAdditionalDocumentsSuccess': {
            data: {
              id: true
            }
          }
        }
      ]
    });

    setIsSubmitting(false);
    navigate('/dashboard/:dealershipSlug/:transactionId/glove-compartment', {
      params: { transactionId, dealershipSlug }
    });
  };

  return (
    <div className="flex flex-col h-dvh w-full">
      <ConsumerDashboardHeader
        centerElement={
          <div className="flex flex-row space-x-spacing-02 items-center">
            <div className="relative">
              <DocumentsIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <p className="heading-03 md:heading-emphasized-02 text-center">
              {t('Add Documents')}
            </p>
          </div>
        }
        rightElement={
          <Button
            size="SMALL"
            variant="GHOST"
            onClick={handleLogout}
            isLoading={loggingOut}
            className="!p-0"
            label={t('Logout')}
          />
        }
        showBorder
      />

      <div className="flex flex-col pt-4 space-y-6 w-full items-center overflow-y-scroll">
        <div className="w-full max-w-screen-md px-spacing-05 md:px-0">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/additional-documents"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>

        <div className="flex flex-col space-y-spacing-04 w-full max-w-screen-md">
          <div className="space-y-spacing-02 w-full px-spacing-05 md:px-0">
            <h2>{t('Capture Documents')}</h2>

            <p>{t('Please capture the document you wish to upload')}</p>
          </div>

          <div className="flex flex-col w-full p-spacing-05 items-center">
            <div className="flex flex-row items-center justify-center">
              {!cameraLoaded && !isSubmitting && (
                <div className="flex absolute top-0 right-0 bottom-0 left-0 items-center justify-center">
                  <Spinner />
                </div>
              )}

              {!capture && (
                <div className="relative">
                  <Webcam
                    ref={webcamRef}
                    audio={false}
                    width={720}
                    height={480}
                    screenshotQuality={1}
                    imageSmoothing={false}
                    screenshotFormat="image/png"
                    className={classNames(
                      'rounded-lg max-w-full aspect-[3/2] object-cover',
                      {
                        'scale-x-[-1]': !isMobile
                      }
                    )}
                    videoConstraints={{
                      width: { min: 720, ideal: 1440 },
                      height: { min: 480, ideal: 960 },
                      aspectRatio: 1.5,
                      facingMode: { ideal: 'environment' }
                    }}
                    onUserMedia={handleCameraLoaded}
                  />
                  <A4PageOverlay heightPercentage={90} />
                </div>
              )}

              {!!capture && (
                <img
                  src={capture}
                  className="rounded-lg max-w-full object-cover"
                />
              )}
            </div>
            <div className="flex flex-row gap-4 mt-7">
              {!capture && (
                <div
                  className="flex w-20 justify-center aspect-square rounded-full border cursor-pointer icon-interactive border-interactive"
                  onClick={() => {
                    void takePicture();
                  }}
                >
                  <div className="relative">
                    <CameraIcon className="w-10 aspect-square" filled />
                  </div>
                </div>
              )}

              {!!capture && (
                <Fragment>
                  <Button
                    variant="SECONDARY"
                    onClick={handleRetake}
                    disabled={!capture || isSubmitting}
                    label={t('Retake')}
                  />

                  <Button
                    variant="PRIMARY"
                    onClick={() => void handleUpload()}
                    disabled={!capture || isSubmitting}
                    label={t('Accept and continue')}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddAdditionalDocumentTypes;
