import { useQuery } from '@tanstack/react-query';
import { IdTheftPrevention } from '@thedealersconcierge/lib/codecs/schema/formSubmissionData';
import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import uploadFileAction from '~/actions/formSubmissions/uploadFileAction';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import DesktopWebcamModal from '~/components/DesktopWebcamModal';
import Header from '~/components/Header';
import CaptureIcon from '~/components/icons/CaptureIcon';
import Checkbox from '~/components/inputs/Checkbox';
import StandOut from '~/components/StandOut';
import { gqlMutationClient } from '~/lib/backend';
import { identityTheftPreventionForm } from '~/lib/form/identity-theft-prevention';
import { loadFontBytes } from '~/lib/pdf';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

export default function Step2() {
  const { t } = useTranslation();
  const mobileCameraInputRef = useRef<HTMLInputElement | null>(null);
  const { transactionId, dealershipSlug } = useParams(
    '/onboarding/:dealershipSlug/:transactionId/step2'
  );
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);
  const { data: meData } = useQuery(meQuery());

  const [error, setError] = useState<string | null>(null);

  const [capture, setCapture] = useState<File | undefined>(undefined);
  const [isDesktopWebcamModalOpen, setIsDesktopWebcamModalOpen] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!capture) {
      // proper error
      return;
    }
    try {
      setIsSubmitting(true);
      // We have to upload the profile image

      const captureIsPng = capture.type.includes('png');
      const captureUpload = await uploadFileAction(
        { dealershipSlug },
        captureIsPng ? 'png' : 'jpg',
        captureIsPng ? 'image/png' : 'image/jpg',
        capture
      );
      const formSubmissionData: IdTheftPrevention = {
        submissionType: 'IDENTITY_THEFT_PREVENTION',
        submissionData: {
          image: URL.createObjectURL(capture), // This is used to render the image on the PDF
          dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
          name: `${meData?.me?.user?.firstName ?? ''} ${meData?.me?.user?.lastName ?? ''}`.trim(),
          ipAddress: meData?.me?.ipAddress ?? 'Unknown IP',
          deviceId: window.navigator.userAgent
        }
      };

      // Generate identity theft prevention PDF
      const pdf = await generatePdf(
        identityTheftPreventionForm(t),
        formSubmissionData.submissionData,
        false,
        undefined,
        loadFontBytes
      );
      const pdfBlob = new Blob([pdf]);

      formSubmissionData.submissionData.image = `tdcfile://${captureUpload.fileId}`;

      // Upload identity verification PDF
      const pdfUpload = await uploadFileAction(
        { dealershipSlug },
        'pdf',
        'application/pdf',
        pdfBlob
      );

      const resp = await gqlMutationClient({
        dealershipSlug
      })({
        onboardingSubmitStep2: [
          {
            transactionId,
            step2: {
              captureFileId: captureUpload.fileId ?? 'no-capture-file-id',
              idTheftForm: JSON.stringify(formSubmissionData),
              idTheftPdfFileId: pdfUpload.fileId ?? 'no-pdf-file-id'
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationOnboardingSubmitStep2Success': {
              data: { status: true }
            }
          }
        ]
      });

      if (resp.onboardingSubmitStep2?.__typename === 'GraphQLError') {
        toast.error(resp.onboardingSubmitStep2?.message ?? 'Unexpected error');
      }

      if (
        resp.onboardingSubmitStep2?.__typename ===
        'MutationOnboardingSubmitStep2Success'
      ) {
        navigate('/dashboard/:dealershipSlug/:transactionId', {
          params: { transactionId, dealershipSlug }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTakeSelfie = useCallback(() => {
    setError(null);

    if (!accepted) {
      setError(
        t('You have to agree to the conditions first before taking a picture.')
      );
      return;
    }

    if (isMobile) {
      mobileCameraInputRef.current?.click();
    } else {
      setIsDesktopWebcamModalOpen(true);
    }
  }, [accepted]);
  const handleMobileImageCapture = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setCapture(event.target.files[0]);
    }
  };
  const handleDesktopImageCapture = (file: File) => {
    setCapture(file);
    setIsDesktopWebcamModalOpen(false);
  };
  const handleCloseDesktopWebcamModal = () => {
    setIsDesktopWebcamModalOpen(false);
  };

  return (
    <div className="flex flex-col h-dvh" data-test-id="onboarding-step2-body">
      <Header
        title={t('Your Transaction Information')}
        totalSteps={2}
        currentStep={2}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={() => {
              logoutAction();
            }}
          >
            {t('Cancel')}
          </button>
        }
      />

      {/**
       * This input opens the camera on mobile devices
       */}
      <input
        ref={mobileCameraInputRef}
        accept="image/*"
        type="file"
        capture="user"
        className="hidden"
        onChange={handleMobileImageCapture}
      />

      {/**
       * This is the webcam modal for desktop
       */}
      <DesktopWebcamModal
        title={t('Capture your face')}
        isOpen={isDesktopWebcamModalOpen}
        orientation="PORTRAIT"
        onClose={handleCloseDesktopWebcamModal}
        onDone={handleDesktopImageCapture}
      />

      <div className="flex flex-col items-center overflow-y-scroll px-4">
        <div className="flex flex-col w-full max-w-screen-md py-20 md:py-30 lg:py-40 space-y-12 justify-between">
          <div className="space-y-6">
            <div className="space-y-5">
              <h1 className="text-heading-2">
                {t('Identity Theft Prevention')}
              </h1>

              <StandOut variant="PRIVACY">
                <p>
                  {t(
                    'Your photo will be used to identify and detect fraud. The image is securely stored.'
                  )}
                </p>
              </StandOut>

              {error && (
                <StandOut variant="ERROR">
                  <p>{error}</p>
                </StandOut>
              )}
            </div>

            <div
              className={classNames(
                'flex flex-row space-x-6 items-start cursor-pointer'
              )}
              onClick={() => {
                setAccepted((a) => !a);
              }}
            >
              <div className="flex mt-1">
                <Checkbox
                  inputId="acknowledged"
                  value={accepted}
                  onChange={() => {
                    setAccepted((a) => !a);
                  }}
                  dataTestId="onboarding-step2-privacy-checkbox"
                />
              </div>
              <div className="flex flex-col space-y-4">
                <p>
                  {t(
                    'By submitting my picture, I agree that the Dealership may use my provided photo/image only for lawful purposes, in this case, to identify, process, and secure information to purchase an automobile.'
                  )}
                </p>
                <p>
                  {t(
                    'All information provided is my own and has been prepared based on my representations alone. No one else has assisted me in the information provided and may be used in my credit application if I choose to do so.'
                  )}
                </p>
              </div>
            </div>

            {!capture && (
              <div
                className="flex flex-col space-y-4 items-center cursor-pointer"
                onClick={handleTakeSelfie}
                data-test-id={'onboarding-step2-capture-profile'}
              >
                <h3>{t('Please take a selfie')}</h3>

                <CaptureIcon className="max-w-64 icon-tertiary" />
              </div>
            )}

            {capture && (
              <div className="relative flex justify-center">
                <img
                  src={
                    URL.createObjectURL(capture) // We can force unwrap because if there's no existing profile picture, there's a capture
                  }
                  className="w-1/2 aspect-square object-contain"
                />
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              disabled={!capture}
              loading={isSubmitting}
              onClick={() => void handleSubmit()}
              dataTestId="onboarding-step-2-submit"
            >
              {t('Submit')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
