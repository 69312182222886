import range from 'lodash/range';
import { PDFDocument } from 'pdf-lib';

/**
 * From a list of Files pointing to PDF files this will give
 *
 * @param urls
 */
export const combinePdfFiles = async (files: File[]) => {
  const pdfDoc = await PDFDocument.create(); // create a new pdf document for bundling

  for (const file of files) {
    const srcPdfDoc = await PDFDocument.load(await file.arrayBuffer());
    const srcPageIndices = range(srcPdfDoc.getPageCount());
    const copiedPages = await pdfDoc.copyPages(srcPdfDoc, srcPageIndices);
    copiedPages.forEach((page) => pdfDoc.addPage(page));
  }

  const combinedPdfBuffer = await pdfDoc.save();

  // Create a blob and trigger the download
  return new Blob([combinedPdfBuffer], { type: 'application/pdf' });
};
