import { gqlMutationClient } from '~/lib/backend';

const requestOtpExistingUserDEVAction = async (email: string) => {
  const resp = await gqlMutationClient({ dealershiplessAuth: true })({
    requestOtpExistingUserDEV: [
      {
        email
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationRequestOtpExistingUserDEVSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (
    !resp.requestOtpExistingUserDEV ||
    resp.requestOtpExistingUserDEV.__typename === 'GraphQLError'
  ) {
    throw new Error(
      resp.requestOtpExistingUserDEV?.message ?? 'Unexpected error'
    );
  }

  return resp.requestOtpExistingUserDEV.data;
};

export default requestOtpExistingUserDEVAction;
