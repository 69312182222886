import { z } from "zod";

// Verbatim values being inserted into the form PDF
export const PrequalApplicationSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().optional(),
  suffix: z.string(),

  // Dates don't exist in JSON. This makes it easier as we don't need to
  // preprocess step for fields we expect to be dates.
  birthdate: z.coerce.date(),
  socialSecurityNumber: z.string(),
  street: z.string(),
  apartmentDetails: z.string(),
  city: z.string(),
  state: z.string(), // The state has to be a two letter ISO code. For backwards compatibility we are not enforcing this in the schema
  zip: z.string(),
  signature: z.string(),

  // Always needed
  name: z.string(),
  dateTime: z.string(),
  deviceId: z.string(),
  ipAddress: z.string(),
});

export const PrequalFormSchema = PrequalApplicationSchema;

export type PrequalFormSchema = z.TypeOf<typeof PrequalFormSchema>;
