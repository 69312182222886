import { Button } from '@thedealersconcierge/components';
import React from 'react';
import type { DocumentDetection } from '~/pages/scanner/_components/Scanner';
import { BaseLayout } from '~/pages/scanner/_components/ScreenBaseLayout';
import { ScreenModes } from './ScreenController';

export default function ScannerScreen({
  scanner,
  pages,
  setScreenMode
}: {
  scanner: React.ReactNode;
  pages: DocumentDetection[];
  setScreenMode: (screenMode: ScreenModes) => void;
}) {
  // Build the thumbnail from the last page
  const thumbnailData = pages.length ? pages[pages.length - 1] : undefined;
  const blob =
    thumbnailData && thumbnailData.cropped
      ? new Blob([thumbnailData.cropped], { type: 'image/png' })
      : null;
  const url = blob ? URL.createObjectURL(blob) : undefined;

  return (
    <BaseLayout>
      <BaseLayout.Content>
        <div className="w-full h-full relative">{scanner}</div>
      </BaseLayout.Content>

      <BaseLayout.Footer>
        <div className="w-full h-full grid grid-cols-2 gap-12 place-items-center p-4">
          {/* THUMBNAIL */}
          <div className="col-span-1">
            {url && (
              <div
                className="w-12 h-12 bg-gray-600 flex-shrink-0 cursor-pointer"
                onClick={() => {
                  setScreenMode('REVIEW_SINGULAR');
                }}
              >
                <img
                  src={url}
                  alt="Page Thumbnail"
                  className="object-cover w-full h-full"
                />
              </div>
            )}
          </div>

          <div className="col-span-1">
            <Button
              size="SMALL"
              label={`Review ${pages.length > 0 ? `(${pages.length})` : ''}`}
              onClick={() => {
                setScreenMode('FINAL_REVIEW');
              }}
            />
          </div>
        </div>
      </BaseLayout.Footer>
    </BaseLayout>
  );
}
