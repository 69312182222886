import { CalendarTodayOutlined } from '@mui/icons-material';
import { format, parse } from 'date-fns';
import { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputBase from '..';
import { InputBackgroundType } from '../inputConfig';

/**
 * ## Date Picker Component
 * [Storybook page](https://backoffice.dev.mytdc.net/storybook/index.html?path=/story/input-dateinput--default&globals=viewport:ipad)
 *
 * Basic usage:
 *
 * ```tsx
 * ...
 * ```
 *
 * The date input picker component represents dates in the format: `yyyy-MM-dd`
 * We do this to strip away timezone
 */
const DateInput: FC<{
  /**
   * The value as a string in the format: `yyyy-MM-dd`
   */
  value: string;
  label: string;
  assistiveMessage?: string;
  errorMessage?: string;
  placeholder: string;
  onChange?: (date: string) => void;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  backgroundType?: InputBackgroundType;
  dataTestId?: string;
}> = ({
  value,
  label,
  assistiveMessage,
  errorMessage,
  placeholder,
  onChange,
  onBlur,
  required,
  disabled,
  className,
  backgroundType = 'DARK',
  dataTestId
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // We can further customize the calendar popover when we have designs for it: https://reactdatepicker.com/#example-calendar-container
  return (
    <InputBase
      inputComponent={
        <DatePicker
          selected={
            value.length ? parse(value, 'yyyy-MM-dd', new Date()) : null
          }
          onChange={(updatedDate: Date | null) => {
            // This is a _date_ picker, so we immediately throw away anything else
            onChange?.(updatedDate ? format(updatedDate, 'yyyy-MM-dd') : '');
          }}
          isClearable={false}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          placeholderText={placeholder}
          onFocus={() => {
            setIsOpen(true);
          }}
          onBlur={() => {
            setIsOpen(false);
            onBlur?.();
          }}
          customInput={<input data-test-id={dataTestId} type="text" />}
          className="w-full bg-transparent !pl-spacing-07 !py-[6px] body-01 outline-none"
          wrapperClassName="w-full"
          calendarIconClassName="!size-6 !p-spacing-02 icon-secondary"
          calendarClassName="bg-primary"
          icon={<CalendarTodayOutlined className="size-6" fontSize="inherit" />}
          showIcon
        />
      }
      label={label}
      assistiveMessage={assistiveMessage}
      errorMessage={errorMessage}
      required={required}
      disabled={disabled}
      backgroundType={backgroundType}
      isBeingUpdated={isOpen}
      className={className}
    />
  );
};

export default DateInput;
