import { Dialog, Transition } from '@headlessui/react';
import { OpenInNew } from '@mui/icons-material';
import { captureException } from '@sentry/react';
import { Button, Spinner } from '@thedealersconcierge/components';
import { useAtomValue } from 'jotai';
import { QRCodeSVG } from 'qrcode.react';
import { FC, Fragment, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import config from '~/config';
import { DealershipIdentifier } from '~/globals';
import { gqlMutationClient } from '~/lib/backend';
import { Link } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';

const QrCodeComponent: FC<{
  transactionId: string;
  dealershipIdentifier: DealershipIdentifier;
}> = ({ transactionId, dealershipIdentifier }) => {
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const kioskMode = useAtomValue(kioskDealershipAtom);
  const { t } = useTranslation();

  const fetchSubmissionToken = async () => {
    setIsLoading(true);
    try {
      const res = await gqlMutationClient(dealershipIdentifier)({
        makeSubmissionToken: {
          __typename: true,
          '...on GraphQLError': {
            message: true
          },
          '...on MutationMakeSubmissionTokenSuccess': {
            data: true
          }
        }
      });

      if (
        res.makeSubmissionToken?.__typename ===
        'MutationMakeSubmissionTokenSuccess'
      ) {
        setToken(res.makeSubmissionToken.data);
      }
    } catch (error) {
      console.error(error);
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  // We always fetch submission token incase Non-kiosk user want to use QR
  // E.g Desktop access
  useEffect(() => {
    void fetchSubmissionToken();

    return () => {
      setToken(null);
    };
  }, []);

  if (isLoading) {
    return (
      <div>
        <Spinner size="LARGE" color="GREY" />
      </div>
    );
  }

  // We redirect it to kiosk mode page since there is essentially no difference other than the URL
  const scannerUrl = `${config.consumerAppUrl}scanner/${transactionId}/${token}/kiosk`;

  return (
    <div className="flex flex-col items-center space-y-spacing-04">
      <Trans t={t}>
        <p>
          Scan the QR code with your phone camera to open the Document Scanner
          <b> on another device</b>. Use it to scan documents and save them as
          PDFs directly from your phone
        </p>
      </Trans>

      {/* Always show this on Kiosk and Consumer Device */}
      {scannerUrl && <QRCodeSVG value={scannerUrl} size={256} />}

      {/* When failed to fetch submission token display error message */}
      {isLoading && !scannerUrl && (
        <code className="bg-gray-200 p-spacing-02">
          Failed to fetch Scanner URL
        </code>
      )}

      {!kioskMode && (
        <Fragment>
          <p>Or</p>

          <Link
            to="/scanner/:transactionId"
            params={{ transactionId }}
            target="_blank"
            rel="noopener noreferrer"
            className="text-inverse bg-blue-600 focus:ring-2 focus:ring-blue-800 focus:ring-offset-2 px-spacing-05 py-spacing-03 w-fit rounded-radius-02 hover:bg-blue-700 active:bg-blue-800 flex flex-row"
          >
            <p>{t('Scan via this Device')}</p>
            <OpenInNew className="ml-2" />
          </Link>
        </Fragment>
      )}

      {!config.isProduction && (
        <>
          <a href={scannerUrl}>Open Scanner for DEV: </a>
          <p>{scannerUrl}</p>
        </>
      )}
    </div>
  );
};

export default function OpenScannerModel({
  transactionId,
  dealershipIdentifier
}: {
  transactionId: string;
  dealershipIdentifier: DealershipIdentifier;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        variant="SECONDARY"
        label={t('Scan Documents')}
      />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-modal"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-spacing-04 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-radius-04 bg-primary p-spacing-06 text-left align-middle shadow-shadow-05 transition-all">
                  <Dialog.Title
                    as="div"
                    className="flex flex-row justify-between items-center"
                  >
                    <h2 className="heading-emphasized-02">
                      {t('Scan Documents')}
                    </h2>

                    <div
                      className="cursor-pointer p-spacing-02"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <X />
                    </div>
                  </Dialog.Title>

                  {isOpen && (
                    <QrCodeComponent
                      transactionId={transactionId}
                      dealershipIdentifier={dealershipIdentifier}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
