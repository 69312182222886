import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';

const updateMyUserAction = async (user: GraphQLTypes['UpdateUserInput']) => {
  const respUser = await gqlMutationClient({ dealershiplessAuth: true })({
    updateMyUser: [
      {
        user
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationUpdateMyUserSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (
    !respUser.updateMyUser ||
    respUser.updateMyUser.__typename === 'GraphQLError'
  ) {
    throw new Error(respUser.updateMyUser?.message ?? 'Unexpected error');
  }

  return respUser.updateMyUser.data;
};

export default updateMyUserAction;
