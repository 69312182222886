import { Routes } from '@generouted/react-router';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import config from './config';
import { makeBrowserInfo } from './globals';
import './i18n';
import './index.css';
import store from './lib/store';

// Making sure the browser info is set before anything else
makeBrowserInfo();

Sentry.init({
  ignoreErrors: [
    // For this error in particular, we can't really do much when requests fail due to network errors.
    // We have already build a wonky-network error message
    'NetworkError: Load failed'
  ],

  dsn: 'https://b184b8b26336ac092947e0b569cdb1fa@o4506535389626368.ingest.sentry.io/4506535396442112',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== 'development' && config.prodEnv !== 'ci',
  environment: config.prodEnv
});

export const queryClient = new QueryClient();

// Render our app!
const rootElement = document.getElementById('root');

if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <Provider store={store.instance}>
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>
  );
}
