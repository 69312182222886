import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { addressSelector } from './address';

export const hardCreditApplicationSelector = Selector('HardCreditApplication')({
  id: true,
  userId: true,
  transactionId: true,
  firstName: true,
  lastName: true,
  middleName: true,
  dob: true,
  socialSecurityNumber: true,
  driversLicenseNumber: true,
  driversLicenseState: true,
  homePhoneNumber: true,
  phoneNumber: true,
  email: true,
  personalReferenceFirstName: true,
  personalReferenceMiddleName: true,
  personalReferenceLastName: true,
  personalReferenceRelationship: true,
  personalReferencePhoneNumber: true,
  personalReferenceAddress: addressSelector,
  formSubmission: {
    id: true,
    file: {
      id: true,
      url: true
    },
    formSubmissionData: true
  },
  createdAt: true
});

export type HardCreditApplicationType = InputType<
  GraphQLTypes['HardCreditApplication'],
  typeof hardCreditApplicationSelector
>;
