import { UseQueryOptions } from '@tanstack/react-query';
import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { gqlQueryClient } from '~/lib/backend';
import { queryClient } from '~/main';
import { vehicleSelector } from '~/querySelectors/vehicle';

const queryKey = (transactionId: string, dealershipSlug: string) => [
  'transaction',
  transactionId,
  dealershipSlug
];
// Define select here to derive typing
const selector = (transactionId: string) =>
  Selector('Query')({
    transaction: [
      { id: transactionId },
      {
        id: true,
        buyerId: true,
        coBuyerId: true,

        // It does not make sense to select buyer / co-buyer here, as they can not be accessed
        // if it is the other party who logs in

        customerSharedData: {
          buyerFirstName: true,
          buyerLastName: true,
          buyerEmail: true,
          buyerPhoneNumber: true,
          buyerName: true,
          coBuyerFirstName: true,
          coBuyerLastName: true,
          coBuyerEmail: true,
          coBuyerPhoneNumber: true,
          coBuyerName: true
        },
        dealership: {
          id: true,
          name: true,
          hasEnabledScanner: true
        },
        vehicle: vehicleSelector,
        tradeVehicle: vehicleSelector,
        salesManager: {
          firstName: true,
          lastName: true,
          email: true
        },
        salesPerson: {
          firstName: true,
          lastName: true,
          email: true
        },
        createdAt: true
      }
    ]
  });
export type TransactionQueryType = InputType<
  GraphQLTypes['Query'],
  ReturnType<typeof selector>
>;

/**
 * This query can be used in all react components. The reason why we store it
 * on the outermost level her (and not just the selector) is to ensure that we
 * reuse the query selector.
 *
 * @returns
 */
export default function transactionQuery(
  transactionId?: string,
  dealershipSlug?: string
): UseQueryOptions<TransactionQueryType> {
  return {
    // Disable the query when transactionId is not provided
    enabled: Boolean(transactionId && dealershipSlug),
    queryKey: queryKey(transactionId ?? '', dealershipSlug ?? ''),
    queryFn: () => {
      return gqlQueryClient(
        dealershipSlug ? { dealershipSlug } : { dealershiplessAuth: true }
      )(selector(transactionId ?? ''));
    }
  };
}

export const resetTransactionQuery = async (
  transactionId: string,
  dealershipSlug: string
) => {
  await queryClient.resetQueries({
    queryKey: queryKey(transactionId, dealershipSlug)
  });
};
