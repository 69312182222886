import { TFunction } from 'i18next';
import { VehicleCondition } from '~/__generated__/backend/zeus';

export type VehicleConditionOption = {
  value: VehicleCondition;
  label: string;
};

export default function vehicleConditionOptions(
  t: TFunction
): VehicleConditionOption[] {
  return [
    { value: VehicleCondition.EXCELLENT, label: t('Excellent') },
    { value: VehicleCondition.GOOD, label: t('Good') },
    { value: VehicleCondition.SATISFACTORY, label: t('Satisfactory') },
    { value: VehicleCondition.UNSATISFACTORY, label: t('Unsatisfactory') }
  ];
}
