import { Dialog, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@thedealersconcierge/components';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import i18n from '~/i18n';
import dealershipQuery, {
  DealershipQueryType
} from '~/queries/dealershipQuery';
import { Link } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';
import { languageAtom } from '~/state/language';

const LanguageSwitcher = ({
  dealership
}: {
  dealership: DealershipQueryType['dealership'];
}) => {
  const { t } = useTranslation();
  // const [loadingNewLanguage, setLoadingNewLanguage] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useAtom(languageAtom);

  const setLanguage = async (lang: string) => {
    try {
      // setLoadingNewLanguage(true);
      setSelectedLanguage(lang);
      await i18n.changeLanguage(lang);
      if (lang !== 'en') {
        setShowConfirmModal(true);
      }
    } finally {
      // setLoadingNewLanguage(false);
    }
  };

  const dealershipLangs = dealership?.supportedLanguages;

  if (!dealershipLangs || dealershipLangs.length < 2) {
    return null;
  }

  return (
    <>
      <Transition appear show={showConfirmModal} as={Fragment}>
        {/* The user _has_ to click the "understood" button */}
        <Dialog as="div" className="relative z-modal" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={
                    'w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6'
                  }
                >
                  <div className="py-4 text-center text-heading-3">
                    <Dialog.Title>
                      {/* We show both english and foreign language version */}
                      Language Disclaimer / {t('Language Disclaimer')}
                    </Dialog.Title>
                  </div>

                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-col space-y-1">
                      <p className="text-body-3">
                        Translations of any materials into languages other than
                        English are intended as a convenience to the non -
                        English - reading public. We cannot guarantee exact
                        translation of same for all content.
                      </p>
                      <p className="text-body-3">
                        By clicking "continue" I agree and acknowledge the above
                        statement.
                      </p>
                    </div>

                    <hr />

                    <div className="flex flex-col space-y-1">
                      {/* We show both english and foreign language version */}

                      <p className="text-body-3">
                        {t(
                          'Translations of any materials into languages other than English are intended as a convenience to the non - English - reading public. We cannot guarantee exact translation of same for all content.'
                        )}
                      </p>

                      <p className="text-body-3">
                        {t(
                          'By clicking "continue" I agree and acknowledge the above statement.'
                        )}
                      </p>
                    </div>

                    <Button
                      className="w-full"
                      variant="PRIMARY"
                      onClick={() => {
                        setShowConfirmModal(false);
                      }}
                      // We show both english and foreign language version
                      label={`Continue / ${t('Continue')}`}
                    ></Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="absolute top-4 right-4">
        <div className="relative inline-flex bg-gray-100 rounded-lg p-1">
          {/* Blue highlight that slides */}
          <div
            className="absolute h-8 bg-blue-500 rounded transition-all duration-300 ease-in-out"
            style={{
              width: `${100 / dealershipLangs.length}%`,
              left: `${(dealershipLangs.findIndex((lang) => lang.toLocaleLowerCase() === selectedLanguage) * 100) / dealershipLangs.length}%`
            }}
          />

          {/* Language buttons */}
          {dealershipLangs.map((lang) => (
            <button
              key={lang}
              onClick={() => {
                void setLanguage(lang.toLowerCase());
              }}
              className={`relative z-10 px-4 py-1 rounded transition-colors duration-300 min-w-20
  ${selectedLanguage === lang.toLowerCase() ? 'text-white' : 'text-gray-700 hover:text-gray-900'}`}
            >
              {lang.toLocaleUpperCase()}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default function IndexRoute() {
  const { t } = useTranslation();

  const kioskMode = useAtomValue(kioskDealershipAtom);
  const { data: dealership } = useQuery(
    dealershipQuery(
      kioskMode?.dealershipSlug
        ? { dealershipSlug: kioskMode.dealershipSlug }
        : undefined
    )
  );

  return (
    <>
      <LanguageSwitcher dealership={dealership?.dealership} />

      <div
        className={classNames(
          'flex flex-col items-center w-screen space-y-12 p-10',
          'sm:p-12',
          'md:p-14',
          'lg:p-16'
        )}
      >
        <img
          src={
            dealership?.dealership?.logoUrl
              ? dealership.dealership.logoUrl
              : '/logo.png'
          }
          className={classNames(
            'h-16 object-contain',
            'sm:h-20',
            'md:h-24',
            'lg:h-28',
            'xl:h-32',
            '2xl:h-36'
          )}
        />

        {kioskMode && (
          <h1 className="text-center">
            {t('Please select what type of user you are.')}
          </h1>
        )}

        <div className="w-full max-w-lg flex flex-col space-y-8">
          {kioskMode && (
            <Link
              data-test-id="kiosk-new-user"
              to="/signup"
              className="bg-interactive-primary text-primary-inverse flex flex-col items-center space-y-4 shadow-lg p-12 rounded-xl"
            >
              <h2>{t('New TDC User')}</h2>

              <p className="text-center">
                <Trans t={t} values={{ dealership: dealership?.dealership }}>
                  Create an account and a new transaction at{' '}
                  <b>{'{{dealership.name}}'}</b>
                </Trans>
              </p>
            </Link>
          )}

          <Link
            to="/signin"
            data-test-id="consumer-sign-in-button"
            className="bg-interactive-tertiary flex flex-col items-center space-y-4 shadow-lg p-12 rounded-xl"
          >
            <h2>{t('Existing TDC User')}</h2>

            <p className="text-center">
              {t('Create a new transaction with your existing credentials')}
            </p>
          </Link>
        </div>
      </div>
    </>
  );
}
