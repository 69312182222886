import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { parsePhoneNumber } from 'libphonenumber-js';

export const getDateFromUnkown = (date: unknown): Date | undefined => {
  if (!date) {
    return undefined;
  }

  // If the object is a date, typeof returns "object"
  if (typeof date === 'object') {
    return date as Date;
  }

  return parseISO(String(date));
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  return phoneNumber
    ? parsePhoneNumber(phoneNumber)?.format(
        parsePhoneNumber(phoneNumber)?.country === 'US'
          ? 'NATIONAL'
          : 'INTERNATIONAL'
      )
    : undefined;
};

/**
 * The standard date string format we use throughout the application is 'yyyy-MM-dd'.
 *
 * However, on PDFs, such as the Prequal, we are using a custom format: 'MM/dd/yyyy'
 *
 * This function takes a Date object as input and returns a string in the display format.
 *
 * The function ensures, the string is correct when this is envoked across different timezones.
 *
 * @param date: Date object
 * @returns string in the format of 'MM/dd/yyyy'
 */
export const dateToDisplayString = (date: Date) => {
  // Ensure the date is converted to UTC
  const utcDate = toZonedTime(date, 'UTC');

  return format(utcDate, 'MM/dd/yyyy');
};
