import { TFunction } from 'i18next';
import { VehicleLifeCycleStage } from '~/__generated__/backend/zeus';

export type VehicleConditionOption = {
  value: VehicleLifeCycleStage;
  label: string;
};

export default function vehicleLifeCycleStageOptions(t: TFunction) {
  return [
    { value: VehicleLifeCycleStage.IS_NEW, label: t('New') },
    { value: VehicleLifeCycleStage.IS_USED, label: t('Used') }
  ];
}
