import { z } from "zod";

export const vinNumber = (message?: string) =>
  z.string().refine(
    (vin) => {
      return vin.length === 17;
    },
    (_vin) => ({
      message: message
        ? message
        : `VIN numbers must have exactly 17 characters`,
    })
  );
