import { Button } from '@thedealersconcierge/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentDetection } from '~/pages/scanner/_components/Scanner';
import { BaseLayout } from '~/pages/scanner/_components/ScreenBaseLayout';
import { ScreenModes } from './ScreenController';

/**
 * This Screen responsible for display a Preview of captured documents for a certain amount of time
 */
export default function CapturePreview({
  draftPage,
  previewTimeInMs,
  afterPreviewCallback,
  setScreenMode,
  pages
}: {
  draftPage: DocumentDetection;
  afterPreviewCallback: () => void;
  previewTimeInMs: number;
  setScreenMode: (screenMode: ScreenModes) => void;
  pages: DocumentDetection[];
}) {
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      afterPreviewCallback();
    }, previewTimeInMs);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!draftPage || !draftPage.cropped) {
    setScreenMode('SCANNER');
    return;
  }

  const blob = new Blob([draftPage.cropped], { type: 'image/png' });
  const previewUrl = URL.createObjectURL(blob);

  // Build the thumbnail from the last page
  const thumbnailData = pages.length ? pages[pages.length - 1] : undefined;
  const thumbnailBlob =
    thumbnailData && thumbnailData.cropped
      ? new Blob([thumbnailData.cropped], { type: 'image/png' })
      : null;
  const thumbnailUrl = thumbnailBlob
    ? URL.createObjectURL(thumbnailBlob)
    : undefined;

  return (
    <BaseLayout>
      <BaseLayout.Content>
        <div className="h-full w-full max-w-lg flex flex-col justify-center items-center bg-gray-200 p-4">
          <div className="w-5/6 mt-6 aspect-auto">
            <img src={previewUrl} alt="Captured Document" draggable={false} />
          </div>

          <p className="mt-4 text-sm text-gray-500">{t('Previewing…')}</p>
        </div>
      </BaseLayout.Content>

      <BaseLayout.Footer>
        <div className="w-full h-full grid grid-cols-2 gap-12 place-items-center p-4">
          {/* THUMBNAIL */}
          <div className="col-span-1">
            {thumbnailUrl && (
              <div className="w-12 h-12 bg-gray-600 flex-shrink-0">
                <img
                  src={thumbnailUrl}
                  alt="Page Thumbnail"
                  className="object-cover w-full h-full"
                />
              </div>
            )}
          </div>

          <div className="col-span-1">
            <Button
              size="SMALL"
              disabled
              label={`Review ${pages.length > 0 ? `(${pages.length})` : ''}`}
              onClick={() => {
                setScreenMode('FINAL_REVIEW');
              }}
            />
          </div>
        </div>
      </BaseLayout.Footer>
    </BaseLayout>
  );
}
