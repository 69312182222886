import { useQuery } from '@tanstack/react-query';
import { Button } from '@thedealersconcierge/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoutAction from '~/actions/logoutAction';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentsIcon from '~/components/icons/DocumentsIcon';
import Checkbox from '~/components/inputs/Checkbox';
import TextInput from '~/components/inputs/TextInput';
import {
  DocumentType,
  getDocumentTitle,
  getDocumentTypeOptions
} from '~/config/formSelectionOptions/additionalDocumentOptions';
import transactionQuery from '~/queries/transactionQuery';
import { Link, useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from '../../_components/ConsumerDashboardHeader';

const AdditionalDocuments = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/additional-documents'
  );
  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = () => {
    setLoggingOut(true);
    logoutAction();
  };
  const [documentType, setDocumentType] =
    useState<DocumentType>('BANK_STATEMENTS');
  const [documentName, setDocumentName] = useState<string>(
    getDocumentTitle('BANK_STATEMENTS', t)
  ); // Bank statements is the preselected option

  const { data: transactionQueryData, isLoading: loadingTransactionData } =
    useQuery(transactionQuery(transactionId, dealershipSlug));
  const transaction = transactionQueryData?.transaction;

  const handleNavigateToUploadPage = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/additional-documents/upload/:documentType',
      {
        params: { transactionId, documentType, dealershipSlug },
        state: { documentName }
      }
    );
  };

  const handleNavigateToCapturePage = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/additional-documents/upload/:documentType/capture',
      {
        params: { transactionId, documentType, dealershipSlug },
        state: { documentName }
      }
    );
  };

  return (
    <div className="flex flex-col h-dvh w-full">
      <ConsumerDashboardHeader
        centerElement={
          <div className="flex flex-row space-x-spacing-02 items-center">
            <div className="relative">
              <DocumentsIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <p className="heading-03 md:heading-emphasized-02 text-center">
              {t('Add Documents')}
            </p>
          </div>
        }
        rightElement={
          <Button
            variant="GHOST"
            size="SMALL"
            onClick={() => {
              handleLogout();
            }}
            disabled={loggingOut}
            className="!p-0"
          />
        }
        showBorder
      />

      <div className="flex flex-col pt-spacing-04 space-y-spacing-05 w-full items-center overflow-y-scroll">
        <div className="w-full max-w-screen-md px-spacing-05 md:px-0">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>

        <div className="flex flex-col space-y-spacing-04 w-full items-center">
          <div className="space-y-spacing-02 w-full px-spacing-05 md:px-0 max-w-screen-md">
            <p className="heading-emphasized-02">{t('Add Documents')}</p>

            <p className="body-02">
              {t(
                'Please upload documents or capture them using the device camera.'
              )}
            </p>
          </div>

          <div className="flex flex-col w-full border-y border-secondary bg-secondary p-spacing-05 items-center">
            <div className="space-y-spacing-05 w-full max-w-screen-md">
              <div className="space-y-spacing-03">
                {getDocumentTypeOptions(t).map(({ value, label }) => (
                  <div
                    className="flex flex-row items-center space-x-spacing-02"
                    key={value}
                  >
                    <Checkbox
                      inputId={label}
                      variant="RADIO_BUTTON"
                      value={documentType === value}
                      onChange={() => {
                        setDocumentType(value);

                        if (value !== 'OTHER') setDocumentName(label);
                      }}
                    />
                    <span className="ml-spacing-02 text-secondary">
                      {label}
                    </span>
                  </div>
                ))}

                {documentType == 'OTHER' && (
                  <TextInput
                    fieldName={'Document name'}
                    labelText="Document name"
                    subtitleText="Document name"
                    placeholder="Enter Document name"
                    required={documentType == 'OTHER'}
                    containerClassName="col-span-4 mt-spacing-02 w-1/3"
                    onChange={(e) => {
                      setDocumentName(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="flex flex-row gap-spacing-04 mt-spacing-05">
                <Button
                  disabled={documentType === 'OTHER' && !documentName.trim()}
                  onClick={handleNavigateToUploadPage}
                  label={t('Upload')}
                />

                {/* Only Enable this Button when dealership have no scanner */}
                {!transaction?.dealership?.hasEnabledScanner && (
                  <Button
                    variant="SECONDARY"
                    disabled={documentType === 'OTHER' && !documentName.trim()}
                    onClick={handleNavigateToCapturePage}
                    label={t('Capture via Camera')}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdditionalDocuments;
