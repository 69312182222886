import { TransactionSource } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';

const customerCreateUnauthenticatedTransactionAction = async (
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  dealershipId: string,
  hasConsentedToSms: boolean,
  language: string,
  salesPersonId: string | null,
  zipCode: string | null
) => {
  const resp = await gqlMutationClient({ dealershipId })({
    customerCreateUnauthenticatedTransaction: [
      {
        source: TransactionSource.KIOSK,
        language: language,
        zipCode,
        user: {
          firstName,
          lastName,
          email,
          phoneNumber,
          role: 'CUSTOMER'
        },
        dealershipId,
        salesPersonId,
        hasConsentedToSms
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationCustomerCreateUnauthenticatedTransactionSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (
    !resp.customerCreateUnauthenticatedTransaction ||
    resp.customerCreateUnauthenticatedTransaction.__typename === 'GraphQLError'
  ) {
    throw new Error(
      resp.customerCreateUnauthenticatedTransaction?.message ??
        'Unexpected error'
    );
  }

  return resp.customerCreateUnauthenticatedTransaction.data;
};

export default customerCreateUnauthenticatedTransactionAction;
