/**
 * Most times we call the backend, we need to provide information about the dealership
 * sometimes we use slug other times ID, it changes
 * This is a structurally sound way to provide it - and also not inadvertedly give the wrong value
 */
export type DealershipIdentifier =
  | { dealershipId: string }
  | { dealershipSlug: string };

export let info: { userAgent: string } = {
  userAgent: 'some-agent' // This is the default value and will be overwritten when running the app with the actual user agent.
};

/**
 * This sets the browser info when running the app
 */
export const makeBrowserInfo = () => {
  info = { userAgent: window.navigator.userAgent };
};
