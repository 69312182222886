import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { DealershipIdentifier } from '~/globals';
import { gqlMutationClient } from '~/lib/backend';
import { AddressType, addressSelector } from '~/querySelectors/address';

const createAddressAction = async (
  values: GraphQLTypes['AddressInput'],
  dealershipIdentifier: DealershipIdentifier,
  transactionId?: string
): Promise<AddressType | undefined> => {
  const resp = await gqlMutationClient(dealershipIdentifier)({
    createAddress: [
      {
        transactionId,
        address: values
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationCreateAddressSuccess': {
          data: addressSelector
        }
      }
    ]
  });

  if (!resp.createAddress || resp.createAddress.__typename === 'GraphQLError') {
    throw new Error(resp.createAddress?.message ?? 'Unexpected error');
  }

  return resp.createAddress.data;
};

export default createAddressAction;
